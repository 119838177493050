import { useEffect, useState } from 'react';
import { getRanks } from '../../../helpers/mongo/crud';
import { connect } from 'react-redux';
import './Ranking.scss';

function Ranking({ local }) {
  const [ranks, setRanks] = useState(undefined);
  const [userScore, setUserScore] = useState();

  useEffect(() => {
    setTimeout(() => {
      getRanks().then((r) => {
        const arrayPlayers = r.reverse();
        const filterPlayers = arrayPlayers.sort((a, b) => (a.score < b.score ? 1 : b.score < a.score ? -1 : 0));
        setRanks(filterPlayers);
        setUserScore(filterPlayers.findIndex((el) => el.cleanedPseudo === local.userID));
      }, 1000);
    });
  }, []);
  return (
    <div className='general-ranking part-dashboard'>
      <h3 className='dashboard-subtitle'>Classement général</h3>
      <div className='ranking-container'>
        {ranks !== undefined && (
          <div className='ranking'>
            <h4 className='user-position'>Votre position</h4>
            {userScore && ranks && (
              <div className={'rank current'}>
                <div className='number'>{userScore + 1 === 1 ? '1er' : userScore + 1 + 'eme'}</div>
                <div className='score'>
                  <p>{ranks[userScore].cleanedPseudo}</p>
                  <span>{ranks[userScore].score} pts</span>
                </div>
              </div>
            )}
            <h4 className='other-positions'>Classement</h4>
            <div className='total-ranking'>
              {ranks.map((el, key) => {
                return (
                  <div className='rank'>
                    <div className='number'>{key + 1 === 1 ? 1 : key + 1}</div>
                    <div className='score'>
                      <p>{el.pseudo}</p>
                      <span>{el.score} pts</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
  };
};

export default connect(mapStateToProps)(Ranking);
