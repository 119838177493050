import { useEffect, useState } from 'react';
import { findArchivedGames } from '../../../helpers/firestore/crud';
import './Parties.scss';

export default function PartiesDash() {
  const [lastGames, setlastGames] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      findArchivedGames().then((r) => {
        setlastGames(r);
      });
    }, 1000);
  }, []);
  return (
    <div className='parties part-dashboard'>
      <h3 className='dashboard-subtitle'>Toutes mes parties</h3>
      <div className='parties-div'>
        {lastGames !== undefined ? (
          lastGames.map((el) => {
            return (
              <div className='parties-div-element'>
                <div className={(el.p1_score > el.p2_score ? 'winner ' : '') + 'you'}>
                  <img src={process.env.PUBLIC_URL + 'img/skins/' + el.players.p1.status.character + '.png'} alt='' />
                  <div className='score-user'>
                    <p>{el.p1_score} pts</p>
                    <p>{el.players.p1.status.userID}</p>
                  </div>
                </div>
                <div className={(el.p2_score > el.p1_score ? 'winner ' : '') + 'ennemy'}>
                  <img src={process.env.PUBLIC_URL + 'img/skins/' + el.players.p2.status.character + '.png'} alt='' />
                  <div className='score-user'>
                    <p>{el.p2_score} pts</p>
                    <p>{el.players.p2.status.userID}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>Il s'agissait de votre première partie !</p>
        )}
      </div>
    </div>
  );
}
