import { Draw } from "./Draw";
import store from "../../../../store/global";
import { writeRoom } from "../../../../helpers/firestore/crud";
import { increment } from "firebase/firestore";
import modes from "../../../../helpers/settings/modes.json";
// GLOBAL VARIABLES

let room;
let local;

store.subscribe(() => {
  room = store.getState().room;
  local = store.getState().local;
});

// MAIN DRAW
export const setDraw = (duration, ctxPath, easing, cache, playerAction, bulletBlockTarget, wallKey, updateWall) => {
  if (local.canvas[ctxPath].ctx) {
    const canvas = local.canvas[ctxPath];
    const playerId = "p" + (local.playerID + 1);
    let startTime;
    let fixedAxis;
    let state;
    let fill;
    let totalDuration = duration; // ms
    if (playerAction === playerId && (ctxPath === 'bullets_p1' || ctxPath === 'bullets_p2' || ctxPath === 'ulti_p1' || ctxPath === 'ulti_p2')) {
      const delay = setTimeout(() => {
        requestAnimationFrame(animate);
      }, 50);
      return () => clearTimeout(delay);
    } else {
      requestAnimationFrame(animate);
    }

    function animate(time) {
      canvas.ctx.clearRect(0, 0, canvas.size.w, canvas.size.h);
      canvas.ctx.beginPath();

      if (!startTime) {
        startTime = time;
      }
      switch (ctxPath) {
        // DRAW PLAYERS
        case "players_1":
        case "players_2": {
          const playerKey = ctxPath === "players_1" ? "p1" : "p2";
          const defenseActive = room.players[playerKey].defense.lastActivation + room.players[playerKey].defense.duration > Date.now();
          state = defenseActive ? "defense" : "normal";
          if (playerKey === playerId) {
            fixedAxis = room.room.settings.axesY;
          } else {
            fixedAxis = 1;
          }
          Draw({
            canvas: {
              ctx: canvas.ctx,
              width: canvas.size.w,
              height: canvas.size.h,
            },
            fill: fill,
            bV: cache,
            eV: room.players[playerKey].x,
            axis: "X",
            fixedAxis: fixedAxis,
            ease: easing,
            duration: duration,
            el: {
              w: room.room.settings.players.w,
              h: room.room.settings.players.h,
            },
            time: time,
            startTime: startTime,
            state: state,
            skin: room.players[playerKey].status.character,
          });
          break;
        }
        // DRAW BULLETS
        case "bullets_p1":
        case "bullets_p2": {
          let bulletTarget;
          let bulletOrigin;
          switch (room.players[playerAction].status.character) {
            case "chat": {
              fill = "#3061d0";
              break;
            }
            case "chouette": {
              fill = "#ac7de6";
              break;
            }
            case "grenouille": {
              fill = "#3ccba0";
              break;
            }
            case "hibou": {
              fill = "#ef9866";
              break;
            }
            case "lapin": {
              fill = "#efd166";
              break;
            }
            default:
              break;
          }
          if (playerAction === playerId) {
            bulletOrigin = room.room.settings.axesY;
            bulletTarget = 1;
          } else {
            bulletOrigin = 1;
            bulletTarget = room.room.settings.axesY;
          }
          Draw({
            canvas: {
              ctx: canvas.ctx,
              width: canvas.size.w,
              height: canvas.size.h,
            },
            fill: fill,
            bV: bulletOrigin,
            eV: bulletTarget,
            axis: "Y",
            fixedAxis: cache[playerAction].x,
            ease: easing,
            duration: duration,
            el: {
              w: room.room.settings.bullets.w,
              h: room.room.settings.bullets.h,
            },
            time: time,
            startTime: startTime,
          });
          break;
        }
        // DRAW ULTI
        case "ulti_p1":
        case "ulti_p2": {
          let bulletTarget;
          let bulletOrigin;
          switch (room.players[playerAction].status.character) {
            case "chat": {
              fill = "#3061d0";
              break;
            }
            case "chouette": {
              fill = "#ac7de6";
              break;
            }
            case "grenouille": {
              fill = "#3ccba0";
              break;
            }
            case "hibou": {
              fill = "#ef9866";
              break;
            }
            case "lapin": {
              fill = "#efd166";
              break;
            }
            default:
              break;
          }
          if (playerAction === playerId) {
            bulletOrigin = room.room.settings.axesY;
            bulletTarget = 1;
          } else {
            bulletOrigin = 1;
            bulletTarget = room.room.settings.axesY;
          }
          Draw({
            canvas: {
              ctx: canvas.ctx,
              width: canvas.size.w,
              height: canvas.size.h,
            },
            fill: fill,
            bV: bulletOrigin,
            eV: bulletTarget,
            axis: "Y",
            fixedAxis: cache[playerAction].x,
            ease: easing,
            duration: duration,
            el: {
              w: room.room.settings.ulti.w,
              h: room.room.settings.ulti.h,
            },
            time: time,
            startTime: startTime,
          });
          break;
        }
        // DRAW OBJECTS
        case "objects": {
          for (let [objectKey, object] of Object.entries(room.objects.walls)) {
            let bV = updateWall ? object.x : cache[objectKey].x;
            if (object.life > 0 && bV) {
              fixedAxis = object.y;
              state = "full";
              if (object.life > room.room.settings.objects.walls.life - modes.room.armoredWalls.value) {
                state = "armored";
              } else if (object.life < room.room.settings.objects.walls.life / 2 && object.life > 1) {
                state = "semibreak";
              } else if (object.life <= 1) {
                state = "break";
              }

              if (playerId === "p2") {
                fixedAxis = room.room.settings.axesY + 1 - fixedAxis;
              }

              Draw({
                canvas: {
                  ctx: canvas.ctx,
                  width: canvas.size.w,
                  height: canvas.size.h,
                },
                fill: fill,
                bV: bV,
                eV: object.x,
                axis: "X",
                fixedAxis: fixedAxis,
                ease: easing,
                duration: duration,
                el: {
                  w: room.room.settings.objects.walls.w,
                  h: room.room.settings.objects.walls.h,
                },
                time: time,
                startTime: startTime,
                state: state,
                skin: "wall",
              });
            }
          }

          break;
        }

        default:
          break;
      }

      const areaY = canvas.size.h / room.room.settings.axesY;
      const animDuration = -startTime + (startTime + totalDuration);
      const animTime = -(startTime - time);
      const canvasGameSize = areaY * (room.room.settings.axesY - 1);
      let trajectorySize = canvas.size.h - areaY * bulletBlockTarget;
      if (playerAction === "p2") {
        trajectorySize = canvas.size.h - areaY * (room.room.settings.axesY + 1 - bulletBlockTarget);
      }

      if (time < startTime + totalDuration && !bulletBlockTarget) {
        requestAnimationFrame(animate);
      } else if (animTime < (animDuration * trajectorySize) / canvasGameSize) {
        requestAnimationFrame(animate);
      } else if (ctxPath === "bullets_p1" || ctxPath === "bullets_p2" || ctxPath === "ulti_p1" || ctxPath === "ulti_p2") {
        const attackKey = ctxPath.includes("bullets") ? "firing" : "ulti";
        const ennemy = playerAction === "p1" ? "p2" : "p1";
        // CAUSE DAMAGE
        if (bulletBlockTarget && playerId === playerAction) {
          // INPUT LIFE OBJECT
          if (room.objects.lastMove + room.room.settings.objects.walls.moveSpeed > Date.now()) {
            // Wall are in animation
            writeRoom(
              local.roomID,
              {
                objects: {
                  walls: {
                    [wallKey]: {
                      life: room.objects.walls[wallKey].life - room.players[playerAction][attackKey].damage,
                    },
                  },
                },
              },
              true
            );
          } else {
            // Wall is fix
            writeRoom(
              local.roomID,
              {
                objects: {
                  updated: Date.now(),
                  walls: {
                    [wallKey]: {
                      life: room.objects.walls[wallKey].life - room.players[playerAction][attackKey].damage,
                    },
                  },
                },
              },
              true
            );
          }
        } else if (room.players[ennemy].x === cache[playerAction].x) {
          // INPUT LIFE PLAYER
          if (
            !room.players[ennemy].defense.isActive &&
            room.players[ennemy].defense.lastActivation + room.players[ennemy].defense.duration < Date.now() &&
            playerId !== ennemy &&
            room.players[ennemy].lastMoveX + room.room.settings.players.moveSpeed < Date.now()
          ) {
            writeRoom(
              local.roomID,
              {
                players: {
                  [ennemy]: {
                    life: room.players[ennemy].life - room.players[playerAction][attackKey].damage,
                  },
                  [playerAction]: {
                    firing: {
                      totalTouched: increment(room.players[playerAction][attackKey].damage),
                      lastTouched: {
                        [attackKey]: Date.now(),
                      },
                    },
                  },
                },
              },
              true
            );
          }
        }
        canvas.ctx.clearRect(0, 0, canvas.size.w, canvas.size.h);
      } else if (ctxPath === "objects") {
        if (local.playerID + 1 === 1 && !room.room.settings.isPaused) {
          if (room.room.settings.gameMode === "wallMove" && !updateWall) {
            const wallsCoolDown = setTimeout(() => {
              drawWalls(true);
            }, room.room.settings.objects.walls.coolDown);
            return () => clearTimeout(wallsCoolDown);
          }
        }
      }
    }
  }
};

const drawWalls = (wallMove) => {
  let newObjects;
  for (let [objectKey, object] of Object.entries(room.objects.walls)) {
    let destination = object.x;
    let direction = object.direction;

    if (wallMove === true) {
      if (object.x < room.room.settings.axesX && object.direction === "right") {
        destination = object.x + 1;
      } else if (object.x === room.room.settings.axesX && object.direction === "right") {
        destination = object.x - 1;
        direction = "left";
      } else if (object.x < room.room.settings.axesX && object.direction === "left" && object.x !== 1) {
        destination = object.x - 1;
      } else if (object.x === 1 && object.direction === "left") {
        destination = object.x + 1;
        direction = "right";
      }
    }
    newObjects = newObjects ? newObjects : room.objects.walls;
    newObjects = {
      ...newObjects,
      [objectKey]: {
        ...newObjects[objectKey],
        x: destination,
        direction: direction,
        life: room.objects.walls[objectKey].life,
      },
    };
  }
  if (wallMove) {
    writeRoom(
      local.roomID,
      {
        objects: {
          lastMove: Date.now(),
          walls: newObjects,
        },
      },
      true
    );
  }
};
