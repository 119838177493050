import axios from 'axios'

// export const getUsers = async () => {
//   axios.get(process.env.REACT_APP_API_URL + '/users')
//   .then((response) =>{
//     console.log(response.data.users);
//   })
//   .catch((err) => {
//     const error = err.response.data.result
//     console.log(error);
//   })
// };

export const updateUser = async (cleanedPseudo, data) => {
  axios.put(process.env.REACT_APP_API_URL + '/users/' + cleanedPseudo, data)
  .then(() =>{
    return
  })
  .catch((err) => {
    const error = err.response.data
    console.log(error);
  })
};

export const getUser = async (cleanedPseudo) => {
  try {
    return axios.get(process.env.REACT_APP_API_URL + '/users/' + cleanedPseudo)
    .then(async (response) => {
      return await response.data.user[0]
    })
    .catch((err) => {
      const error = err.response.data
      console.log(error);
    })
  } catch (e) {
    return e;
  }
};

export const getRanks = async () => {
  try {
    return axios.get(process.env.REACT_APP_API_URL + '/users/', {
      order: true
    })
    .then(async (response) => {
      const ranking = response.data.users
      console.log(ranking)
      return await ranking
    })
    .catch((err) => {
      const error = err.response.data
      console.log(error);
    })
  } catch (e) {
    return e;
  }
};