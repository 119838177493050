import "./Pause.scss";
import { writeRoom } from "../../../helpers/firestore/crud";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import bonus from "../../../helpers/settings/bonus.json";
import { cardChoices } from "../Canvas/Functions/Choices";

function Pause({ room, local }) {
  let isPaused = room.room.settings.isPaused;
  let pauseTimer = room.room.duration.pauseTime;
  const [roundTime, setRoundTime] = useState(pauseTimer);
  let round = room.room.settings.round;
  const [cards, setCards] = useState({ card1: undefined, card2: undefined });

  useEffect(() => {
    if (isPaused === true) {
      setCards({
        card1: Object.keys(bonus.round.attack)[Math.floor(Math.random() * Object.keys(bonus.round.attack).length)],
        card2: Object.keys(bonus.round.defense)[Math.floor(Math.random() * Object.keys(bonus.round.attack).length)],
      });
      const interval = setInterval(() => {
        pauseTimer = pauseTimer - 1;
        setRoundTime(pauseTimer);
        if (pauseTimer <= 0) {
          clearInterval(interval);
          writeRoom(
            local.roomID,
            {
              room: {
                settings: {
                  isPaused: false,
                },
              },
            },
            true
          );
        }
      }, 1000);
    }
  }, [isPaused]);

  function chooseCard(e) {
    if (room.players["p" + (local.playerID + 1)].capacities["card" + (round - 1)] !== null) {
      return;
    }
    cardChoices(e.target.id);
    if (round === 2) {
      writeRoom(
        local.roomID,
        {
          players: {
            ["p" + (local.playerID + 1)]: {
              capacities: {
                card1: e.target.id,
              },
            },
          },
        },
        true
      );
      let cards = document.querySelectorAll(".card");
      cards.forEach((element) => {
        if (element.id === e.target.id) {
          element.parentNode.classList.add("active");
        } else {
          element.parentNode.classList.add("disabled");
        }
      });
      new Audio(process.env.PUBLIC_URL + "/sounds/bonusSelection.mp3").play();
    }
    if (round === 3) {
      writeRoom(
        local.roomID,
        {
          players: {
            ["p" + (local.playerID + 1)]: {
              capacities: {
                card2: e.target.id,
              },
            },
          },
        },
        true
      );
      let cards = document.querySelectorAll(".card");
      cards.forEach((element) => {
        if (element.id === e.target.id) {
          element.parentNode.classList.add("active");
        } else {
          element.parentNode.classList.add("disabled");
        }
      });
      let audio = new Audio(process.env.PUBLIC_URL + "/sounds/bonusSelection.mp3").play();
      audio.volume = 0.2;
      audio.play();
    }
  }

  return (
    <div className="pause-container">
      <div className="pause">
        <h3 className="fauna">Pause stratégique</h3>
        <p className="fauna">Choisis une carte pour ta prochaine action</p>
        <div className="cards">
          <button onClick={(e) => chooseCard(e)}>
            <img src={process.env.PUBLIC_URL + "/img/bonus/" + cards.card1 + ".webp"} id={cards.card1} class="card" alt="" />
          </button>
          <button onClick={(e) => chooseCard(e)}>
            <img src={process.env.PUBLIC_URL + "/img/bonus/" + cards.card2 + ".webp"} id={cards.card2} class="card" alt="" />
          </button>
        </div>
        <p>Temps restant : {roundTime} sec</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    local: state.local,
  };
};

export default connect(mapStateToProps)(Pause);
