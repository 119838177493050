import { combineReducers, createStore } from "redux";
import { roomReducer, localReducer } from "./reducers/index";

const rootReducer = combineReducers({
  local: localReducer,
  room: roomReducer
})

const store = createStore(rootReducer);

export default store;