import "./GameHeader.scss";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import roomSetup from "../../../helpers/settings/room-setup.json";
import { writeRoom } from "../../../helpers/firestore/crud";
import { getUser } from "../../../helpers/mongo/crud";

function GameHeader({ room, local }) {
  let isPaused = room.room.settings.isPaused;
  let round = room.room.settings.round;
  let roundTotalTime = room.room.duration.maxRoundDuration;
  let pauseTime = roomSetup.room.duration.pauseTime;
  let p1Alive = room.players.p1.status.alive;
  let p2Alive = room.players.p2.status.alive;
  const maxLife = roomSetup.room.settings.players.maxLife;
  const [lifeP1, setLifep1] = useState();
  const [lifeP2, setLifep2] = useState();
  const [roundTime, setRoundTime] = useState();
  const [settings, setSettings] = useState({});
  const [pseudos, setPseudos] = useState({ p1: "", p2: "" });
  let actualTime;
  let roomStartingTime = Math.round(room.room.status.gameStart / 1000);
  let roundsTime = roundTotalTime * round;

  useEffect(() => {
    if (room.players.p1.life > 0) {
      setLifep1((room.players.p1.life / maxLife) * 100);
    } else {
      writeRoom(
        local.roomID,
        {
          players: {
            p1: {
              status: {
                alive: false,
              },
            },
          },
        },
        true
      );
      setLifep1(0);
    }
    if (room.players.p2.life > 0) {
      setLifep2((room.players.p2.life / maxLife) * 100);
    } else {
      writeRoom(
        local.roomID,
        {
          players: {
            p2: {
              status: {
                alive: false,
              },
            },
          },
        },
        true
      );
      setLifep2(0);
    }
  }, [room.players.p1.life, room.players.p2.life]);

  useEffect(() => {
    if (isPaused === false) {
      setRoundTime(0);
      const interval = setInterval(() => {
        actualTime = Math.round(Date.now() / 1000);
        if (round === 1) {
          setRoundTime(actualTime - roomStartingTime);
        }
        if (round === 2) {
          setRoundTime(actualTime - roomStartingTime - pauseTime - roundTotalTime);
        }
        if (round === 3) {
          setRoundTime(actualTime - roomStartingTime - pauseTime * 2 - roundTotalTime * (round - 1));
        }
        if ((actualTime - roomStartingTime >= roundsTime && round === 1) || p1Alive === false || p2Alive === false) {
          clearInterval(interval);
          updateRound();
        }
        if ((actualTime - roomStartingTime >= roundsTime + pauseTime * 1 && round === 2) || p1Alive === false || p2Alive === false) {
          clearInterval(interval);
          updateRound();
        }
      }, 1000);
    }
  }, [isPaused]);

  function updateRound() {
    if(local.playerID === 0) {
      if (round < room.room.settings.totalRounds && p1Alive === true && p2Alive === true) {
        writeRoom(
          local.roomID,
          {
            room: {
              settings: {
                round: round + 1,
                isPaused: true,
              },
            },
          },
          true
        );
      } else {
      }
    }
  }

  useEffect(() => {
    setSettings({
      ...settings,
      round: round,
      totalRounds: room.room.settings.totalRounds,
    });
  }, [local.roomID]);

  useEffect(() => {
    if (round !== 1) {
      setSettings({ ...settings, round: round });
    }
  }, [round]);

  useEffect(() => {
    const newpseudos = {};
    for (let player in room.players) {
      getUser(room.players[player].status.userID).then((r) => {
        newpseudos[player] = r.pseudo;
      });
    }
    setPseudos(newpseudos);
  }, []);

  return (
    <div className="players">
      <div className="playerContent">
        <div className={"player " + room.players.p1.status.character}>
          <img src={process.env.PUBLIC_URL + "img/skins/" + room.players.p1.status.character + ".png"} alt="" />
        </div>
        <div className="playerInfo">
          <div className="playerNameLife">
            <div className="playerName">
              {pseudos.p1}
              {room.players["p" + (local.playerID + 1)].status.userID === room.players.p1.status.userID ? " (Toi)" : ""}
            </div>
            <p>{Math.round((lifeP1 * maxLife) / 100)}</p>
          </div>
          <div className={"healthbar " + room.players.p1.status.character}>
            <div className={"life " + room.players.p1.status.character} style={{ width: `${lifeP1}%` }}></div>
          </div>
        </div>
      </div>
      <div className="timerManche">
        <div className="timer">
          {roundTime > 9 ? "" : "0"}
          {roundTime}s
        </div>
        <p className="manche">
          Manche {settings.round}/{settings.totalRounds}
        </p>
      </div>
      <div className="playerContent">
        <div className="playerInfo">
          <div className="playerNameLife">
            <p>{Math.round((lifeP2 * maxLife) / 100)}</p>
            <div className="playerName">
              {pseudos.p2}
              {room.players["p" + (local.playerID + 1)].status.userID === room.players.p2.status.userID ? "(Toi)" : ""}
            </div>
          </div>
          <div className={"healthbar " + room.players.p2.status.character}>
            <div className={"life " + room.players.p2.status.character} style={{ width: `${lifeP2}%` }}></div>
          </div>
        </div>
        <div className={"player " + room.players.p2.status.character}>
          {" "}
          <img src={process.env.PUBLIC_URL + "img/skins/" + room.players.p2.status.character + ".png"} alt="" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    local: state.local,
  };
};

export default connect(mapStateToProps)(GameHeader);
