import "./Dashboard.scss";
import Stats from "./Stats/Stats";
import Parties from "./Parties/Parties";
import Ranking from "./Ranking/Ranking";
import { connect } from "react-redux";
import ButtonSkew from "../ButtonSkew/ButtonSkew";
import { archiveRoom } from "../../helpers/firestore/crud";
import { updateUser } from "../../helpers/mongo/crud";
import { getUser } from "../../helpers/mongo/crud";
import { useEffect, useState } from "react";
import { increment } from "firebase/firestore";

function Dashboard({ room, local }) {
  let p1Alive = room.players.p1.status.alive;
  let p2Alive = room.players.p2.status.alive;
  let p1Name = room.players.p1.status.userID;
  let p2Name = room.players.p2.status.userID;
  let winner;
  let looser;
  const [winnerScore, setWinnerScore] = useState();
  const [looserScore, setLooserScore] = useState();

  const home = () => {
    new Audio(process.env.PUBLIC_URL + "/sounds/click.mp3").play();
    window.location.replace(process.env.REACT_APP_APPURL);
  };

  useEffect(() => {
    winner = p1Alive ? "p1" : "p2";
    looser = p2Alive ? "p1" : "p2";
    let winnerS = Math.round(
      room.room.settings.gameScore.win + room.players[winner].life + (room.players[winner].firing.totalTouched / room.players[winner].firing.totalShoots) * 100
    );
    setWinnerScore(winnerS);
    let looserS = -4 * room.players[winner].life;
    setLooserScore(looserS);
    if (local.playerID === 0) {
      const score1 = winner === "p1" ? winnerS : looserS;
      const score2 = winner === "p2" ? winnerS : looserS;
      updateUser(room.players[winner].status.userID, { score: increment(winnerS) });
      getUser(room.players[looser].status.userID).then((el) => {
        if (looserS > el.score) {
          looserS = -1 * el.score;
        }
        updateUser(room.players[looser].status.userID, { score: increment(looserS) });
      });
      archiveRoom(score1, score2);
    }
  }, []);

  return (
    <div className="main-dash">
      <div className="header-dash">
        <img src={process.env.PUBLIC_URL + "/icons/logo.svg"} alt="" />
        <div className="info-joueur">
          <ButtonSkew name="Relancer une partie" onClick={() => home()} btnClass="small" />
          <div className="pseudo-joueur">
            <p>{local.userID}</p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "img/skins/" + room.players["p" + (local.playerID + 1)].status.character + ".png"}
            alt=""
            className={"img-joueur " + room.players.p1.status.character}
          />
        </div>
      </div>
      <div className="main-dashboard-container">
        <div className="vs-element">
          <div className={p1Alive ? "you winner" : "you"}>
            <img src={process.env.PUBLIC_URL + "img/skins/" + room.players.p1.status.character + ".png"} alt="" />
            <div className="score-user">
              <p>
                {p1Name} {p1Alive ? " a gagné !" : " a perdu !"}
              </p>
              <p>{p1Alive ? "+ " + Math.round(winnerScore) + " points gros bg 😎" : Math.round(looserScore) + " points gros looser 💀"}</p>
            </div>
          </div>
          <div className={p2Alive ? "ennemy winner" : "ennemy"}>
            <img src={process.env.PUBLIC_URL + "img/skins/" + room.players.p2.status.character + ".png"} alt="" />
            <div className="score-user">
              <p>
                {p2Name} {p2Alive ? " a gagné !" : " a perdu !"}
              </p>
              <p>{p2Alive ? "+" + Math.round(winnerScore) + " points gros bg 😎" : Math.round(looserScore) + " points gros looser 💀"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="other-info">
        <Stats />
        <Parties />
        <Ranking />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    local: state.local,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setArchive: (payload) =>
      dispatch({
        type: "IS_ARCHIVED",
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
