import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { readRoom } from "../../helpers/firestore/crud";

function CharacterChooser({ local, updateUserCharacter }) {
  const [query] = useState(new URLSearchParams(window.location.search));
  const characters = ["chat", "chouette", "grenouille", "hibou", "lapin"];
  const [forbiddenCharacter, setforbiddenCharacter] = useState("none");

  const slider = React.useRef();

  const changeCharacter = (op) => {
    if ((local.userCharacter === 0) & (op === -1) || (local.userCharacter === 4) & (op === 1)) {
      return;
    }
    updateUserCharacter(local.userCharacter + op);
    if (op > 0) {
      slider.current.style.transform = "translateX(" + -op * ((local.userCharacter + op) * 312) + "px)";
      let audio = new Audio(process.env.PUBLIC_URL + "/sounds/characterLanding.mp3");
      audio.volume = 0.3;
      audio.play();
    } else {
      slider.current.style.transform = "translateX(" + op * ((local.userCharacter + op) * 312) + "px)";
      let audio = new Audio(process.env.PUBLIC_URL + "/sounds/characterLanding.mp3");
      audio.volume = 0.3;
      audio.play();
    }
  };

  const checkP1Character = () => {
    readRoom(query.get("room")).then((r) => {
      if (!r.players) {
        return;
      }
      setforbiddenCharacter(characters.findIndex((el) => el === r.players.p1.status.character));
    });
  };

  useEffect(() => {
    checkP1Character();
  }, [query]);

  return (
    <div className="characterChoice">
      <div onClick={(e) => changeCharacter(-1)} className="characterChoice__chevron right">
        <FaChevronLeft color="#f5f5f5" size={40} />
      </div>

      <div className={"characterChoice__img character" + local.userCharacter}>
        <div className="characterChoice__img__slider" ref={slider}>
          {characters.map((name, key) => (
            <img
              key={key}
              className={(key === local.userCharacter && "active") + (key === forbiddenCharacter ? " forbidden" : "")}
              rank={key}
              src={"/img/skins/" + name + ".png"}
              alt="Character img"
            ></img>
          ))}
        </div>
        {forbiddenCharacter === local.userCharacter && <p className="characterChoice__img__forbidden">Personnage non disponible</p>}
      </div>

      <div onClick={(e) => changeCharacter(1)} className="characterChoice__chevron left">
        <FaChevronRight color="#f5f5f5" size={40} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserCharacter: (payload) =>
      dispatch({
        type: "UPDATE_USER_CHARACTER",
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CharacterChooser);
