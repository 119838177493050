import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

initializeApp({
  apiKey: "AIzaSyBMgA6tkyuNV7COfep6ugEBCL5k1MmMlgk",
  authDomain: "digital-event-2022.firebaseapp.com",
  projectId: "digital-event-2022",
  storageBucket: "digital-event-2022.appspot.com",
  messagingSenderId: "156431824228",
  appId: "1:156431824228:web:030e9d86dd64604a1d3671"
});

// Initialize Firebase
const db = getFirestore();

export default db