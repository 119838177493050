import Timer from './Timer/Timer';
import Roulette from './Roulette/Roulette';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import modesFile from '../../helpers/settings/modes.json';
import { writeRoom } from '../../helpers/firestore/crud';
import { modesChoice } from './Functions/Modes';

function Launching({ room, local }) {
  const [modeSelected, setmodeSelected] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (local.playerID === 0) {
      const modes = modesFile.room;
      const selectedmode = Object.keys(modes)[Math.floor(Math.random() * Object.keys(modes).length)];
      modesChoice(selectedmode);
      writeRoom(
        local.roomID,
        {
          room: {
            settings: {
              gameMode: selectedmode,
            },
          },
        },
        true
      );
    }

    setTimeout(() => {
      setmodeSelected(true);
    }, 5000);
  }, []);

  useEffect(() => {
    if (room.room.settings.gameMode !== '') {
      setReady(true);
    }
  }, [room.room.settings.gameMode]);

  return (
    <div className='launching'>
      {modeSelected === true && <Timer />}
      {(modeSelected === false) & (ready === true) && <Roulette />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

export default connect(mapStateToProps)(Launching);
