import { easeInQuad, linear, easeOutQuint } from '../../../../helpers/easing/easing';
import store from '../../../../store/global';
const imagesList = [
  { url: '/img/players/', skin: 'chat', state: 'normal' },
  { url: '/img/players/', skin: 'lapin', state: 'normal' },
  { url: '/img/players/', skin: 'grenouille', state: 'normal' },
  { url: '/img/players/', skin: 'hibou', state: 'normal' },
  { url: '/img/players/', skin: 'chouette', state: 'normal' },
  { url: '/img/players/', skin: 'chat', state: 'defense' },
  { url: '/img/players/', skin: 'lapin', state: 'defense' },
  { url: '/img/players/', skin: 'grenouille', state: 'defense' },
  { url: '/img/players/', skin: 'hibou', state: 'defense' },
  { url: '/img/players/', skin: 'chouette', state: 'defense' },
  { url: '/img/objects/', skin: 'wall', state: 'full' },
  { url: '/img/objects/', skin: 'wall', state: 'semibreak' },
  { url: '/img/objects/', skin: 'wall', state: 'break' },
  { url: '/img/objects/', skin: 'wall', state: 'armored' },
];
const images = [];

imagesList.forEach((item) => {
  const img = new Image();
  img.src = process.env.PUBLIC_URL + item.url + item.state + '/' + item.skin + '.png';
  images.push({ img: img, skin: item.skin, state: item.state });
});

let room;

store.subscribe(() => {
  room = store.getState().room;
});

export const Draw = ({ canvas, fill, bV, eV, axis, fixedAxis, ease, duration, el, time, startTime, state, skin }) => {
  const area = {
    X: canvas.width / (room.room.settings.axesX + 1),
    Y: canvas.height / (room.room.settings.axesY + 1),
  };

  canvas.ctx.fillStyle = fill;

  bV = bV * area[axis];
  eV = eV * area[axis];

  const diff = eV - bV;

  let elapsedTime = Math.min(time - startTime, duration);

  let eased;

  if (ease === 'linear') {
    eased = linear(elapsedTime, bV, diff, duration);
  } else if (ease === 'easeInQuad') {
    eased = easeInQuad(elapsedTime, bV, diff, duration);
  } else if (ease === 'easeOutQuint') {
    eased = easeOutQuint(elapsedTime, bV, diff, duration);
  }

  if (bV > eV) {
    if (eased < eV) {
      eased = eV;
    }
  } else {
    if (eased > eV) {
      eased = eV;
    }
  }

  let pos;
  switch (axis) {
    case 'X': {
      pos = {
        X: eased,
        Y: fixedAxis * area['Y'],
      };
      break;
    }
    case 'Y': {
      pos = {
        X: fixedAxis * area['X'],
        Y: eased,
      };
      break;
    }
    default:
      break;
  }

  if (skin && state) {
    const selecImg = images.find((img) => img.skin === skin && img.state === state);
    canvas.ctx.drawImage(selecImg.img, pos.X - el.w / 2, pos.Y - el.h / 2, el.w, el.h);
  } else {
    canvas.ctx.fillRect(pos.X - el.w / 2, pos.Y - el.h / 2, el.w, el.h);
  }

  return startTime;
};
