import React, { useEffect, useState } from "react";
import { initRoom, joinRoom } from "../Functions/Rooms";
import ButtonSkew from "../../ButtonSkew/ButtonSkew";
import "./RoomManager.scss";
import { connect } from "react-redux";
import CharacterChooser from "../../CharacterChooser/CharacterChooser";
import { readRoom } from "../../../helpers/firestore/crud";

function RoomManager({ local }) {
  const [query] = useState(new URLSearchParams(window.location.search));
  const [roomIDInput, setroomIDInput] = useState(query.get("room") || "");
  const [tabActive, setTabActive] = useState(query.get("query") || "create");

  const characters = ["chat", "chouette", "grenouille", "hibou", "lapin"];

  const changeTab = (e) => {
    setTabActive(e.target.dataset.id);
    new Audio(process.env.PUBLIC_URL + "/sounds/click.mp3").play();
  };

  // Handle query parameters for link sharing
  useEffect(() => {
    const join = query.get("room");
    const req = query.get("query");
    if (req === "join") {
      readRoom(join).then((r) => {
        if (r.players !== undefined) {
          if (["landing", "waiting"].indexOf(r.room.status.stage) === -1) {
            window.location.replace(process.env.REACT_APP_APPURL);
          }
        }
      });
    } else if (req === "create") {
      initRoom(characters[local.userCharacter]);
    }
  }, [query]);

  return (
    <div className="roomManager">
      <div className="roomManager__wrapper">
        <CharacterChooser />
        <div className="form">
          <div className="form__swapper">
            <div className={`${"form__swapper--create"} ${tabActive === "create" && "active"}`}>
              <p data-id="create" onClick={(e) => changeTab(e)}>
                Créer une partie
              </p>
            </div>
            <div className={`${"form__swapper--join"} ${tabActive === "join" && "active"}`}>
              <p data-id="join" onClick={(e) => changeTab(e)}>
                Rejoindre une partie
              </p>
            </div>
          </div>
          <div className="form__wrapper">
            {tabActive === "join" && <input onInput={(e) => setroomIDInput(e.target.value)} className="form__wrapper__code" type="text" placeholder="Code" value={roomIDInput} />}

            <div className="form__wrapper__button">
              {tabActive === "create" ? (
                <ButtonSkew onClick={(e) => initRoom(characters[local.userCharacter])} name="Créer une partie" btnClass="small" active={tabActive} />
              ) : (
                <ButtonSkew onClick={(e) => joinRoom(roomIDInput, characters[local.userCharacter], false)} name="REJOINDRE" active={tabActive} btnClass="small" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserCharacter: (payload) =>
      dispatch({
        type: "UPDATE_USER_CHARACTER",
        payload,
      }),
    updateRoomId: (payload) =>
      dispatch({
        type: "UPDATE_ROOM_ID",
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomManager);
