export function updateRoom(payload) {
  return { type: "UPDATE_ROOM", payload }
};

export function updateRoomId(payload) {
  return { type: "UPDATE_ROOM_ID", payload }
};

export function setReady(payload) {
  return { type: "SET_READY", payload}
};

export function updatePlayerID(payload) {
  return { type: "UPDATE_PLAYER_ID", payload }
};

export function updateUserID(payload) {
  return { type: "UPDATE_USER_ID", payload }
};

export function newError(payload) {
  return { type: "NEW_ERROR", payload }
};

export function updateCanvas(payload) {
  return { type: "UPDATE_CANVAS", payload }
};

export function updateStage(payload) {
  return { type: "UPDATE_STAGE", payload }
};

export function setMatchmaking(payload) {
  return { type: "SET_MATCHMAKING", payload }
};

export function openHelp(payload) {
  return { type: "SET_HELP", payload }
};

export function openAuth(payload) {
  return { type: "SET_AUTH", payload }
};

export function setToken(payload) {
  return { type: "SET_TOKEN", payload }
};

export function updateUserName(payload) {
  return { type: "UPDATE_USER_NAME", payload }
};

export function updateUserCharacter(payload) {
  return { type: "UPDATE_USER_CHARACTER", payload }
};

export function setArchive(payload) {
  return { type: "IS_ARCHIVED", payload }
};

export function setPlayable(payload) {
  return { type: "SET_PLAYABLE", payload }
};