import db from "./credentials";
import { query, where, collection, getDocs, doc, setDoc, getDoc, onSnapshot, deleteDoc, deleteField, updateDoc } from "firebase/firestore";
import { updateRoom, setPlayable } from "../../store/actions/index";
import store from "../../store/global";
import setup from "../settings/room-setup.json";

let local;
let room;

store.subscribe(() => {
  local = store.getState().local;
  room = store.getState().room;
});

const collectionName = process.env.REACT_APP_COLLECTION_ROOMS_NAME;

const checkID = (id, collection) => {
  readRoom(collection, id).then((r) => {
    if (r !== undefined) {
      return false;
    } else {
      return true;
    }
  });
};

const generateID = () => {
  const id = Math.floor(Math.random() * (9999 - 1000) + 1000);
  return id.toString();
};

export const createRoom = async () => {
  try {
    let id = generateID();
    while (checkID(id, collectionName) === false) {
      id = generateID();
    }
    await setDoc(doc(db, collectionName, id), setup);
    return id;
  } catch (e) {
    return e;
  }
};

export const writeRoom = async (documentName, data, merge) => {
  try {
    await setDoc(doc(db, collectionName, documentName), data, { merge: merge });
  } catch (e) {
    return e;
  }
};

//
export const readRoom = async (documentName) => {
  try {
    const docRef = doc(db, collectionName, documentName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  } catch (e) {
    return e;
  }
};

export const listenRoom = async (documentName, archive) => {
  let col = collectionName;
  if (archive) {
    col = "archive";
  }
  try {
    const unsub = onSnapshot(doc(db, col, documentName), (doc) => {
      store.dispatch(updateRoom(doc.data()));
    });
    return unsub;
  } catch (e) {
    return e;
  }
};

export const bulkDeleteRooms = async () => {
  try {
    const query = await getDocs(collection(db, collectionName));
    query.forEach(async (d) => {
      if (d.data().room.duration.startingTime) {
        const startingTime = d.data().room.duration.startingTime;
        const minutes = Math.floor((Date.now() - startingTime) / 60000);
        if (minutes > parseInt(process.env.REACT_APP_ROOM_EXPIRE_TIME)) {
          deleteDoc(doc(db, collectionName, d.id));
        }
      }
    });
    return;
  } catch (e) {
    return e;
  }
};

export const archiveRoom = async (score1, score2) => {
  try {
    let id = local.roomID + "_" + Date.now();
    await setDoc(doc(db, "archive", id), { ...room, p1_archived: room.players.p1.status.userID, p2_archived: room.players.p2.status.userID, p1_score: score1, p2_score: score2 });
    return id;
  } catch (e) {
    return e;
  }
};

export const findArchivedGames = async () => {
  try {
    const games = [];
    const col = collection(db, "archive");
    for (let i = 1; i < 3; i++) {
      const q = query(col, where("p" + i + "_archived", "==", local.userID));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        games.push(doc.data());
      });
    }
    return games;
  } catch (e) {
    return e;
  }
};

export const findArchivedGamesByPlayer = async (id) => {
  try {
    const games = [];
    const col = collection(db, "archive");
    for (let i = 1; i < 3; i++) {
      const q = query(col, where("p" + i + "_archived", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        games.push(doc.data());
      });
    }
    return games;
  } catch (e) {
    return e;
  }
};

export const isMatchmaking = async () => {
  try {
    const docRef = doc(db, "game", "settings");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data().matchmaking;
    }
  } catch (e) {
    return e;
  }
};

export const isPlayable = async () => {
  try {
    const unsub = onSnapshot(doc(db, 'game', 'settings'), (doc) => {
      store.dispatch(setPlayable(doc.data().playable));
    });
    return unsub;
  } catch (e) {
    return e;
  }
};

export const enqueueRoom = async (roomID) => {
  try {
    await setDoc(doc(db, "matchmaking", "creating"), { [roomID]: roomID }, { merge: true });
  } catch (e) {
    return e;
  }
};

export const unenqueueRoom = async (roomID) => {
  try {
    await updateDoc(doc(db, "matchmaking", "creating"), { [roomID]: deleteField() }, { merge: true });
  } catch (e) {
    return e;
  }
};

export const getRooms = async (currents) => {
  try {
    const docRef = doc(db, "matchmaking", "creating");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (e) {
    return e;
  }
};

export const updateMatchmaking = async () => {
  try {
    const old = await isMatchmaking();
    await setDoc(
      doc(db, "game", "settings"),
      {
        matchmaking: !old,
      },
      { merge: true }
    );
  } catch (e) {
    return e;
  }
};

export const getAllRooms = async () => {
  try {
    let rooms = [];
    const querySnapshot = await getDocs(collection(db, "rooms"));
    querySnapshot.forEach((doc) => {
      rooms.push(doc);
    });
    return rooms;
  } catch (e) {}
};

export const deleteRoom = async (id) => {
  try {
    await deleteDoc(doc(db, "rooms", id));
  } catch (e) {}
};
