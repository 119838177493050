import { useEffect, useState } from "react";
import "./Admin.scss";
import { findArchivedGamesByPlayer } from "../../helpers/firestore/crud";
import { getRanks } from "../../helpers/mongo/crud";
import { connect } from "react-redux";

function Admin({ local, setMatchmaking }) {
  // const [rooms, setRooms] = useState([]);
  const [ranks, setRanks] = useState([]);

  // const matchmaking = () => {
  //   updateMatchmaking();
  //   setMatchmaking(!local.matchmaking);
  // };

  // const bulkDelete = () => {
  //   const key = "Oui je m'en branle";
  //   const result = prompt("Tape '" + key + "' pour valider ton choix.", "");
  //   if (result === key) {
  //     bulkDeleteRooms();
  //   }
  // };

  useEffect(() => {
    setInterval(() => {
      getRanks().then((r) => {
        setRanks(r.reverse())
      })
    }, 2000);
    findArchivedGamesByPlayer('cantonny2martin').then((r) => {
      console.log(r)
    })
  }, []);

  return (
    <div className="admin">
      <div className="admin__container">
        <section>
          <h3>Ranking</h3>
          <div className="ranks">
            {ranks.map((el, key) => {
              return (
                <div className="rank">
                  <p>{key + 1 === 1 ? 1 : key + 1}</p>
                  <p>{el.score}</p>
                  <p>{el.pseudo}</p>
                  <p>{el.cleanedPseudo}</p>
                  {/* <p>{el.}</p> */}
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMatchmaking: (payload) =>
      dispatch({
        type: "SET_MATCHMAKING",
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
