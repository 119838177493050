import setup from '../../helpers/settings/room-setup.json';

const localState = {
  roomID: 0,
  playerID: undefined,
  error: '',
  ready: false,
  userID: undefined,
  userName: '',
  userCharacter: 0,
  canvas: {},
  matchmaking: '',
  sections: {
    help: false,
    auth: false
  },
  token: 0,
  archived: false,
  playable: true
};

function localReducer(state = localState, action) {
  switch (action.type) {
    case 'UPDATE_ROOM_ID': {
      return { ...state, roomID: action.payload };
    }
    case 'UPDATE_PLAYER_ID': {
      return { ...state, playerID: action.payload };
    }
    case 'UPDATE_USER_ID': {
      return { ...state, userID: action.payload };
    }
    case 'UPDATE_CANVAS': {
      return { ...state, canvas: { ...state.canvas, ...action.payload } };
    }
    case 'SET_READY': {
      return { ...state, ready: action.payload };
    }
    case 'NEW_ERROR': {
      return { ...state, error: action.payload };
    }
    case 'UPDATE_STAGE': {
      return { ...state, stage: action.payload };
    }
    case 'SET_MATCHMAKING': {
      return { ...state, matchmaking: action.payload };
    }
    case 'SET_HELP': {
      return { ...state, sections: {help: action.payload} };
    }
    case 'SET_AUTH': {
      return { ...state, sections: {auth: action.payload} };
    }
    case 'SET_TOKEN': {
      return { ...state, token: action.payload };
    }
    case 'UPDATE_USER_NAME': {
      return { ...state, userName: action.payload };
    }
    case 'UPDATE_USER_CHARACTER': {
      return { ...state, userCharacter: action.payload };
    }
    case 'IS_ARCHIVED': {
      return { ...state, archived: action.payload };
    }
    case 'SET_PLAYABLE': {
      return { ...state, playable: action.payload };
    }
    default:
      return state;
  }
}

const roomState = setup;

function roomReducer(state = roomState, action) {
  switch (action.type) {
    case 'UPDATE_ROOM': {
      return action.payload;
    }
    default:
      return state;
  }
}

export { roomReducer, localReducer };
