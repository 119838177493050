import { writeRoom } from '../../../helpers/firestore/crud';
import store from '../../../store/global';
import modes from '../../../helpers/settings/modes.json'
import { increment } from 'firebase/firestore';

let local;

store.subscribe(() => {
  local = store.getState().local;
});

export const modesChoice = (effect) => {
  switch(effect) {
    case "wallMove":
      break
    case "armoredWalls":
      writeRoom(
        local.roomID, {
          room: {
            settings: {
              objects: {
                walls: {
                  life: increment(modes.room.armoredWalls.value)
                }
              }
            }
          }
        },
        true
      ) 
      break
    case "lifeBonus":
        writeRoom(
          local.roomID, {
            players: {
              p1: {
                life: increment(modes.room.lifeBonus.value)
              },
              p2: {
                life: increment(modes.room.lifeBonus.value)
              }
            },
          },
          true
        ) 
        break
    case "bulletSpeed":
      writeRoom(
        local.roomID, {
          players: {
            p1: {
              firing: {
                firingSpeed: increment(-1 * modes.room.bulletSpeed.value)
              }
            },
            p2: {
              firing: {
                firingSpeed: increment(-1 * modes.room.bulletSpeed.value)
              }
            },
          },
        },
        true
      ) 
      break
    default:
      break
    }
  }