import { useState } from "react";
import "./Capacities.scss";
import { useEffect } from "react";
import { connect } from "react-redux";

function Capacities({ room, local }) {
  let isPaused = room.room.settings.isPaused;
  const [loadingUlti, setLoadingUlti] = useState();
  const [loadingShield, setLoadingShield] = useState();
  let ultiCoolDown = room.players["p" + (local.playerID + 1)].ulti.coolDown + room.players["p" + (local.playerID + 1)].ulti.firingSpeed;
  let shieldCoolDown = room.players["p" + (local.playerID + 1)].defense.coolDown + room.players["p" + (local.playerID + 1)].defense.duration;

  useEffect(() => {
    if (isPaused === false) {
      setLoadingUlti(true);
      setTimeout(() => {
        setLoadingUlti(false);
      }, ultiCoolDown);
    }
  }, [room.players["p" + (local.playerID + 1)].ulti.lastActivation]);

  useEffect(() => {
    if (isPaused === false) {
      setLoadingShield(true);
      setTimeout(() => {
        setLoadingShield(false);
      }, shieldCoolDown);
    }
  }, [room.players["p" + (local.playerID + 1)].defense.lastActivation]);

  return (
    <div className="capacities">
      <div className={(loadingUlti ? "isLoading" : "") + ' capacities__child'}>
        <div className="capacity">
          <img src={process.env.PUBLIC_URL + "/img/ulti.jpg"} alt="" />
          <div className={loadingUlti ? "loader loading" : "loader"} style={loadingUlti ? { animation: "reloader-red " + ultiCoolDown / 1000 + "s" } : { animation: "" }}></div>
        </div>

        <p className={(loadingUlti ? 'loading ' : '') + "capacities__text ulti"}>{loadingUlti ? "Rechargement en cours..." : "Attaque prête !"}</p>
      </div>
      <div className={(loadingShield ? "isLoading" : "") + ' capacities__child'}>
        <div className="capacity">
          <img src={process.env.PUBLIC_URL + "/img/bouclier.jpg"} alt="" />
          <div
            className={loadingShield ? "loader loading" : "loader"}
            style={loadingShield ? { animation: "reloader-blue " + shieldCoolDown / 1000 + "s" } : { animation: "" }}
          ></div>
        </div>
        <p className={(loadingShield ? 'loading ' : '') + "capacities__text shield"}>{loadingShield ? "Rechargement en cours..." : "Prêt à défendre !"}</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    local: state.local,
  };
};

export default connect(mapStateToProps)(Capacities);
