import axios from 'axios'
import store from "../../../store/global";
import { setToken, newError, updateUserID, updateUserName } from '../../../store/actions';

export const getUsers = async () => {
  axios.get(process.env.REACT_APP_API_URL + '/users')
  .then((response) =>{
    console.log(response.data.users);
  })
  .catch((err) => {
    const error = err.response.data.result
    console.log(error);
  })
};

export const login = async ({ firstname, lastname, token, password }) => {
  let id = firstname + lastname
  let body;

  if(password) {
    id = id.replace(/\s/g,'').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    body = {
      password: password
    }
  } else {
    body = {
      token: token
    }
  }

  axios.post(process.env.REACT_APP_API_URL + '/users/login/' + id, body)
  .then((response) =>{
    if(response.status === 200) {
      const token = response.data.token
      store.dispatch(setToken(token))
      store.dispatch(updateUserID(response.data.user.cleanedPseudo));
      store.dispatch(updateUserName(response.data.user.pseudo));
    }
  })
  .catch((err) => {
    console.log(err)
    const error = err.response.data.result
    store.dispatch(newError(error))
    console.log(error);
  })
};

export const signup = async (pseudo, firstname, lastname, password) => {
  let cleanedPseudo = firstname + lastname
  cleanedPseudo = cleanedPseudo.replace(/\s/g,'').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

  if(cleanedPseudo.length <= 4) {
    store.dispatch(newError('too_short_name'))
    return
  }
  
  axios.post(process.env.REACT_APP_API_URL + '/users/', {
    cleanedPseudo: cleanedPseudo,
    password: password,
    pseudo: pseudo
  })
  .then((response) =>{
    if(response.status === 200) {
      const token = response.data.token
      store.dispatch(setToken(token))
      store.dispatch(updateUserID(response.data.user.cleanedPseudo));
      store.dispatch(updateUserName(response.data.user.pseudo));
    }
  })
  .catch((err) => {
    const error = err.response.data.result
    store.dispatch(newError(error))
    console.log(err.response);
  })
};