import React from "react";
import "./FloatingItems.scss";

export default function FloatingItems() {
  const planet = React.useRef();
  const triangle = React.useRef();
  const planet2 = React.useRef();
  const triangle2 = React.useRef();

  return (
    <div className="floatingItems">
      <div className="floatingItems__wrapper">
        <img ref={planet} className="planet floating" speed="1.2" src="/img/planet.svg" alt="" />
        <img ref={planet2} className="planet2 floating" speed="1.2" src="/img/planet-2.svg" alt="" />
        <img ref={triangle} className="triangle floating" speed="1.2" src="/img/triangle.svg" alt="" />
        <img ref={triangle2} className="triangle2 floating" speed="1.2" src="/img/triangle-2.svg" alt="" />
      </div>
    </div>
  );
}
