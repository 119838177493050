import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux'
import store from './store/global'
import "./assets/fonts/Fonts.scss"
import "./helpers/styleVariables/variables.scss"
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
