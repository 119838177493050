import { login, signup } from '../Functions/Auth';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ButtonSkew from '../../ButtonSkew/ButtonSkew';
import { useCookies } from 'react-cookie';
import './Auth.scss';
import { getRanks } from '../../../helpers/mongo/crud';

function Auth({ openAuth, local, updateUserID }) {
  const [userFirstName, setuserFirstName] = useState('');
  const [userLastName, setuserLastName] = useState('');
  const [userPass, setuserPass] = useState('');
  const [userPseudo, setuserPseudo] = useState('');
  const [seconduserPass, setseconduserPass] = useState('');
  const [match, setMatch] = useState(true);
  const [tabActive, setTabActive] = useState('signup');
  const [,, setCookies] = useCookies(['token']);
  const [rank, setRank] = useState(undefined);

  const changeTab = () => {
    if (tabActive === 'signin') {
      setTabActive('signup');
    } else {
      setTabActive('signin');
    }
  };

  const handleForm = async (type) => {
    if (type === 'in') {
      login({firstname: userFirstName, lastname: userLastName, password: userPass});
    } else {
      signup(userPseudo, userFirstName, userLastName, userPass);
    }
  };

  const logOut = () => {
    updateUserID(undefined);
    setCookies('token', undefined, {
      path: '/',
    });
  };

  useEffect(() => {
    if (userPass === seconduserPass && userPass !== '') {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [userPass, seconduserPass]);

  useEffect(() => {
    getRanks().then((r) => {
      r.forEach((el, index) => {
        if (el.cleanedPseudo === local.userID) {
          setRank(index);
        }
      });
    });
  }, [local.userID]);

  return (
    <div className='auth'>
      <div className='auth__bg'></div>
      <div className='auth__wrapper'>
        <img className='auth__wrapper__close' src='/icons/close.svg' alt='icone croix' onClick={(e) => openAuth(false)} />
        {local.userID === undefined ? (
          <div>
            <h3>{tabActive === 'signup' ? 'Créer un compte' : 'Se connecter'}</h3>
            {tabActive === 'signup' && (
              <input onChange={(e) => setuserPseudo(e.target.value)} value={userPseudo} className='form__wrapper__pseudo' type='text' placeholder='Pseudo' />
            )}
            <input onChange={(e) => setuserFirstName(e.target.value)} value={userFirstName} className='form__wrapper__first half' type='text' placeholder='Nom' />
            <input onChange={(e) => setuserLastName(e.target.value)} value={userLastName} className='form__wrapper__last half' type='text' placeholder='Prénom' />
            <input onChange={(e) => setuserPass(e.target.value)} className={`${'form__wrapper__mdp'} ${match === true && ' match'}`} type='password' placeholder='Mot de passe' />
            {tabActive === 'signup' && (
              <input
                onChange={(e) => setseconduserPass(e.target.value)}
                className={`${'form__wrapper__mdp'} ${match === true && ' match'}`}
                type='password'
                placeholder='Répète le mot de passe'
              />
            )}
            {tabActive === 'signin' ? (
              <ButtonSkew onClick={(e) => handleForm('in')} name='Se connecter' btnClass='small' />
            ) : (
              <ButtonSkew onClick={(e) => handleForm('up')} name='Créer un compte' btnClass='small' />
            )}
            {tabActive === 'signup' ? (
              <p className='auth__wrapper__switch' onClick={(e) => changeTab()}>
                Se connecter
              </p>
            ) : (
              <p className='auth__wrapper__switch' onClick={(e) => changeTab()}>
                Créer un compte
              </p>
            )}
          </div>
        ) : (
          <div>
            <h3>Mon Compte</h3>
            <p className='auth__wrapper__bonjour'>
              Bonjour <span>{local.userName}</span> !
            </p>
            <p className='auth__wrapper__rank'>
              Tu es actuellement classé <span>{rank !== undefined && rank}</span> au classement général.
            </p>
            {local.userID !== undefined && (
              <p className='auth__wrapper__logout' onClick={(e) => logOut()}>
                Se déconnecter
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openAuth: (payload) =>
      dispatch({
        type: 'SET_AUTH',
        payload,
      }),
    updateUserID: (payload) =>
      dispatch({
        type: 'UPDATE_USER_ID',
        payload,
      }),
    setToken: (payload) =>
      dispatch({
        type: 'SET_TOKEN',
        payload,
      }),
    newError: (payload) =>
      dispatch({
        type: 'NEW_ERROR',
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
