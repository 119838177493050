import './App.scss';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import RoomWaiting from './components/Init/RoomWaiting/RoomWaiting';
import RoomManager from './components/Init/RoomManager/RoomManager';
import { isMatchmaking, isPlayable, listenRoom } from './helpers/firestore/crud';
import FloatingItems from './components/Statics/FloatingItems/FloatingItems';
import DashedLines from './components/Statics/DashedLines/DashedLines';
import InGame from './components/InGame/InGame';
import Dashboard from './components/Dashboard/Dashboard';
import Launching from './components/Launching/Launching';
import Matchmaking from './components/Init/Matchmaking/Matchmaking';
import Help from './components/Statics/Help/Help';
import Auth from './components/Init/Auth/Auth';
import Error from './components/Statics/Error/Error';
import Admin from './components/Admin/Admin';
import { useCookies } from 'react-cookie';
import { login } from './components/Init/Functions/Auth';

function App({ room, local, setReady, setMatchmaking, openHelp, openAuth, setToken }) {
  const [cookies, setCookies] = useCookies(['token']);
  const [isAdmin, setisAdmin] = useState(false);
  const [query] = useState(new URLSearchParams(window.location.search));

  useEffect(async () => {
    if (local.roomID !== 0) {
      setReady(true);
      listenRoom(local.roomID, local.archived);
    }
    setToken(cookies.token);
  }, [local.roomID]);

  useEffect(() => {
    if(local.token !== 0) {
      login({token: local.token})
    }
    setCookies('token', local.token, {
      path: '/',
    });
  }, [local.token]);

  useEffect(() => {
    if (local.userID !== undefined) {
      openAuth(false);
    } else {
      openAuth(true);
    }
  }, [local.userID]);

  useEffect(() => {
    isMatchmaking().then((value) => {
      setMatchmaking(value);
    });
    isPlayable()
  }, []);

  useEffect(() => {
    if(room.room.leaved === true) {
      window.location.replace(process.env.REACT_APP_APPURL)
    }
  }, [room.room.leaved])

  useEffect(() => {
    const admin = query.get('admin');
    if (admin === '7Y8NF8738FSOPSQDDQ') {
      setisAdmin(true);
    }
  }, [query]);

  return (
    <div className={'App ' + (room.room.status.stage !== undefined && room.room.status.stage)}>
      {local.playable === true ? (
        <div>
        {local.sections.auth === true && <Auth />}
        <div className='App__wrapper'>
          {room.room.status.stage === 'landing' && local.matchmaking === false && <RoomManager />}
          {room.room.status.stage === 'landing' && local.matchmaking === true && <Matchmaking />}
          {room.room.status.stage === 'waiting' && <RoomWaiting />}
          {room.room.status.stage === 'launching' && <Launching />}
          {room.room.status.stage === 'playing' && <InGame />}
          {room.room.status.stage === 'dashboard' && <Dashboard />}
        </div>
        {local.sections.help === true && <Help />}
        {room.room.status.stage !== 'playing' && room.room.status.stage !== 'dashboard' && <DashedLines />}
        <img className='App__openhelp' src='/icons/info.svg' onClick={(e) => openHelp(true)} alt='' />
        {room.room.status.stage === 'landing' && (
          <div className='App__openauth'>
            <img src='/icons/user.svg' onClick={(e) => openAuth(true)} alt='' />
            {local.userID !== undefined && (
              <p>
                Connecté en tant que <span>{local.userName}</span>
              </p>
            )}
          </div>
        )}
        <Error />
        <FloatingItems />
      </div>
      ) : (
        <div className="not-playable">
          <p className='not-playable__title'>En attendant le tournoi, une petite pause dans le jeu !</p>
          <p className='not-playable__text'>Halto vous a vachement plu, merci encore de vos retours ! Mettez-vous en 4G pour réduire le lag, la connexion de l'école a un peu de mal.</p>
          <div className='not-playable__account'>
            <p>Pensez à re-créer un compte quand le tournoi commence.</p>
          </div>
        </div>
      )}
      {isAdmin && <Admin />}
      <p className="version-control">Numéro de version : {process.env.REACT_APP_PROJECT_VERSION}</p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReady: (payload) =>
      dispatch({
        type: 'SET_READY',
        payload,
      }),
    updateStage: (payload) =>
      dispatch({
        type: 'UPDATE_STAGE',
        payload,
      }),
    setMatchmaking: (payload) =>
      dispatch({
        type: 'SET_MATCHMAKING',
        payload,
      }),
    openHelp: (payload) =>
      dispatch({
        type: 'SET_HELP',
        payload,
      }),
    openAuth: (payload) =>
      dispatch({
        type: 'SET_AUTH',
        payload,
      }),
    updateUserID: (payload) =>
      dispatch({
        type: 'UPDATE_USER_ID',
        payload,
      }),
    setToken: (payload) =>
      dispatch({
        type: 'SET_TOKEN',
        payload,
      }),
    newError: (payload) =>
      dispatch({
        type: 'NEW_ERROR',
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
