import { increment } from "firebase/firestore";
import { writeRoom } from "../../../../helpers/firestore/crud";
import store from "../../../../store/global";

// GLOBAL VARIABLES

let room;
let local;
let keyPressed = false;

store.subscribe(() => {
  room = store.getState().room;
  local = store.getState().local;
});

// DETECT KEYDOWN

export const movePlayers = (e) => {
  const date = Date.now();
  switch (e.keyCode) {
    case 39:
      move(1, date);
      break;
    case 32:
      attack(date);
      break;
    case 37:
      move(-1, date);
      break;
    case 38:
      ulti(date);
      break;
    case 40:
      defense(date);
      break;
    default:
      break;
  }
  keyPressed = true;
};

export const pressKey = (e) => {
  keyPressed = false;
};

// FIRERING IN FIRESTORE
export const attack = (moveDate) => {
  const playerFiring = "p" + (local.playerID + 1);
  const lastMove = room.players[playerFiring].lastMoveX;
  const lastBullet = room.players[playerFiring].firing.lastBullet;
  if (lastMove + room.room.settings.players.moveSpeed < moveDate && (lastBullet + room.players[playerFiring].firing.firingSpeed < moveDate || !lastBullet) && !keyPressed) {
    writeRoom(
      local.roomID,
      {
        players: {
          [playerFiring]: {
            firing: {
              lastBullet: moveDate,
              totalShoots: increment(room.players[playerFiring].firing.damage),
            },
          },
        },
      },
      true
    );
    let audio = new Audio(process.env.PUBLIC_URL + "/sounds/shoot.mp3");
    audio.volume = 0.5;
    audio.play();
  }
};

// CHANGE PLAYER X IN FIRESTORE
export const move = (direction, moveDate) => {
  const playerMoving = "p" + (local.playerID + 1);
  const lastMove = room.players[playerMoving].lastMoveX;
  const newValue = room.players[playerMoving].x + direction;
  if (!keyPressed && lastMove + room.room.settings.players.moveSpeed < moveDate && newValue <= room.room.settings.axesX && 1 <= newValue) {
    writeRoom(
      local.roomID,
      {
        players: {
          ["p" + (local.playerID + 1)]: {
            lastMoveX: moveDate,
            lastX: room.players[playerMoving].x,
            x: increment(direction),
          },
        },
      },
      true
    );
  } else {
    return;
  }
};

// USE ULTIMATE
export const ulti = (actionDate) => {
  const playerActing = "p" + (local.playerID + 1);
  const lastActivation = room.players[playerActing].ulti.lastActivation;
  let ultiCoolDown = room.players["p" + (local.playerID + 1)].ulti.coolDown + room.players["p" + (local.playerID + 1)].ulti.firingSpeed;
  if (
    lastActivation + room.players[playerActing].ulti.coolDown + room.players[playerActing].ulti.firingSpeed < actionDate &&
    !keyPressed &&
    actionDate - room.room.status.gameStart > ultiCoolDown
  ) {
    writeRoom(
      local.roomID,
      {
        players: {
          [playerActing]: {
            ulti: {
              lastActivation: actionDate,
            },
            firing: {
              totalShoots: increment(room.players[playerActing].ulti.damage),
            },
          },
        },
      },
      true
    );
    let audio = new Audio(process.env.PUBLIC_URL + "/sounds/ulti.mp3");
    audio.volume = 0.3;
    audio.play();
  } else {
    return;
  }
};

// USE DEFENSE
export const defense = (actionDate) => {
  const playerActing = "p" + (local.playerID + 1);
  const lastActivation = room.players[playerActing].defense.lastActivation;
  let shieldCoolDown = room.players["p" + (local.playerID + 1)].defense.coolDown + room.players["p" + (local.playerID + 1)].defense.duration;
  if (
    lastActivation + room.players[playerActing].defense.coolDown + room.players[playerActing].defense.duration < actionDate &&
    !keyPressed &&
    actionDate - room.room.status.gameStart > shieldCoolDown
  ) {
    writeRoom(
      local.roomID,
      {
        players: {
          [playerActing]: {
            defense: {
              isActive: true,
              lastActivation: actionDate,
            },
          },
        },
      },
      true
    );
    const activeSchield = setTimeout(() => {
      writeRoom(
        local.roomID,
        {
          players: {
            [playerActing]: {
              defense: {
                isActive: false,
              },
            },
          },
        },
        true
      );
    }, room.players[playerActing].defense.duration);
    let audio = new Audio(process.env.PUBLIC_URL + "/sounds/defense.mp3");
    audio.volume = 0.5;
    audio.play();
    return () => clearTimeout(activeSchield);
  } else {
    return;
  }
};
