import "./DashedLines.scss";
import leftDash from "../../../assets/img/left-dash.png";
import bottomDash from "../../../assets/img/bottom-dash.png";

function DashedLines() {
  return (
    <div>
      <img src={leftDash} alt="" className="left-dash" />
      <img src={bottomDash} alt="" className="bottom-dash" />
    </div>
  );
}

export default DashedLines;
