import { writeRoom } from '../../../../helpers/firestore/crud';
import store from '../../../../store/global';
import bonus from '../../../../helpers/settings/bonus.json'
import { increment } from 'firebase/firestore';

// GLOBAL VARIABLES

let local;

store.subscribe(() => {
  local = store.getState().local;
});

export const cardChoices = (effect) => {
  switch(effect) {
    case "life":
      writeRoom(
        local.roomID, {
          players: {
            ['p' + (local.playerID + 1)]: {
              life: increment(bonus.round.defense.life.value)
            }
          }
        },
        true
      ) 
      break
    case "reloadShield":
      writeRoom(
        local.roomID, {
          players: {
            ['p' + (local.playerID + 1)]: {
              defense: {
                coolDown: increment(-1 * bonus.round.defense.reloadShield.value)
              }
            }
          }
        },
        true
      ) 
      break
    case "resistanceShield":
        writeRoom(
          local.roomID, {
            players: {
              ['p' + (local.playerID + 1)]: {
                defense: {
                  duration: increment(bonus.round.defense.resistanceShield.value)
                }
              }
            }
          },
          true
        ) 
        break
    case "damageUlti":
      writeRoom(
        local.roomID, {
          players: {
            ['p' + (local.playerID + 1)]: {
              ulti: {
                damage: increment(bonus.round.attack.damageUlti.value)
              }
            }
          }
        },
        true
      ) 
      break
    case "reloadAtk":
      writeRoom(
        local.roomID, {
          players: {
            ['p' + (local.playerID + 1)]: {
              firing: {
                firingSpeed: increment(-1 * bonus.round.attack.reloadAtk.value)
              }
            }
          }
        },
        true
      ) 
      break
    case "damageMultiplier":
      writeRoom(
        local.roomID, {
          players: {
            ['p' + (local.playerID + 1)]: {
              firing: {
                damage: increment(bonus.round.attack.damageMultiplier.value)
              }
            }
          }
        },
        true
      ) 
      break
    default:
      break;
    }
  }