import "./Roulette.scss";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import modesFile from "../../../helpers/settings/modes.json";

function Roulette({ local, room }) {
  const [modeList, setmodeList] = useState();
  const [currentMode, setcurrentMode] = useState(0);
  const modeContainer = useRef();
  const duration = 2000;

  const shuffle = () => {
    const modes = modesFile.room;
    let modesList = [];
    for (const [modeKey] of Object.entries(modes)) {
      if (modes[modeKey] !== modes[room.room.settings.gameMode]) {
        modesList.push(modeKey);
      }
    }
    modesList.push(room.room.settings.gameMode);
    setmodeList(modesList);
  };

  const slide = () => {
    const modes = modeContainer.current.childNodes;
    modeContainer.current.childNodes.forEach((el, key) => {
      setTimeout(() => {
        setcurrentMode(key);
      }, key * (duration / modes.length));
      modeContainer.current.style.transform = "translateY(-" + key * 230 + "px)";
    });
  };

  useEffect(() => {
    shuffle();
    setTimeout(slide, 1000);
  }, []);

  return (
    <div className="roulette">
      <p class="roulette_room-id">{local.roomID}</p>
      <div className="roulette__wrapper">
        {modeList && (
          <div ref={modeContainer} className="roulette__slider">
            {modeList.map((key, i) => {
              return (
                <div key={key} id={i} className={"roulette__mode " + (i === currentMode ? " active" : " inactive") + (!!(i === currentMode - 1) ? " previous" : "")}>
                  <div className="roulette__mode__card">
                    <p>{modesFile.room[key].name}</p>
                    <img src={"/img/cards/" + key + ".jpg"} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

export default connect(mapStateToProps)(Roulette);
