import './Ending.scss';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { writeRoom } from '../../helpers/firestore/crud';

function Ending({ room, local }) {
  let playerAlive = room.players['p' + (local.playerID + 1)].status.alive;
  let endTimer = room.room.duration.endTime;
  const [endTime, setEndTime] = useState(endTimer);

  useEffect(() => {
    const interval = setInterval(() => {
      endTimer = endTimer - 1;
      setEndTime(endTimer);
      if (endTimer <= 0) {
        clearInterval(interval);
        writeRoom(
          local.roomID,
          {
            room: {
              status: {
                stage: 'dashboard',
              },
            },
          },
          true
        );
      }
    }, 1000);
  }, []);

  return (
    <div className='current-game-stats-container'>
      <div className='current-game-stats'>
        <img
          class={room.players['p' + (local.playerID + 1)].status.character}
          src={process.env.PUBLIC_URL + 'img/skins/' + room.players['p' + (local.playerID + 1)].status.character + '.png'}
          alt=''
        />
        <h3 class='fauna'>{room.players['p' + (local.playerID + 1)].status.userID}</h3>
        <h1 class={'winner fauna ' + room.players['p' + (local.playerID + 1)].status.character}>
          {' '}
          <span class='fauna' span>
            {playerAlive ? 'Bravo ! Tu as gagné !' : 'Mince, tu as perdu...'}
          </span>{' '}
        </h1>

        <p>Redirection vers la page dashboard dans {endTime} secondes...</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    local: state.local,
  };
};

export default connect(mapStateToProps)(Ending);
