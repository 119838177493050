import React, { useEffect, useState } from 'react';
import './Canvas.scss';
import { connect } from 'react-redux';
import { movePlayers, pressKey } from './Functions/Moves';
import { setDraw } from './Functions/DrawSettings';

function Canvas({ room, updateCanvas }) {
  const [ctx, setCtx] = useState();
  const [canvasReady, setCanvasReady] = useState(false);
  const [cache, setCache] = useState();
  const [canvasWidth, setCanvasWidth] = useState();
  const [canvasHeight, setCanvasHeight] = useState();
  const canvasComp = React.useRef();

  const generateCanvas = () => {
    return new Promise((resolve) => {
      const canvasContent = canvasComp.current.childNodes;
      setCtx({
        players_1: canvasContent[0].getContext('2d'),
        players_2: canvasContent[1].getContext('2d'),
        bullets_p1: canvasContent[2].getContext('2d'),
        bullets_p2: canvasContent[3].getContext('2d'),
        ulti_p1: canvasContent[4].getContext('2d'),
        ulti_p2: canvasContent[5].getContext('2d'),
        objects: canvasContent[6].getContext('2d'),
      });
      setCanvasWidth(canvasComp.current.clientWidth);
      setCanvasHeight(canvasComp.current.clientHeight);
      resolve();
    });
  };

  // RESPONSIVE
  useEffect(() => {
    window.addEventListener('resize', () => {
      setCanvasWidth(canvasComp.current.clientWidth);
      setCanvasHeight(canvasComp.current.clientHeight);
      setDraw(10, 'objects', 'easeInQuad', room.objects.walls, false, false, false, true);
      drawPlayers('players_1', 1, false);
      drawPlayers('players_2', 1, false);
    });
  }, []);

  // Generate Canvas & Listen keydown
  useEffect(() => {
    if (!room.room.settings.isPaused) {
      setCache(room);
      generateCanvas();
      setCanvasReady(true);
      document.addEventListener('keydown', movePlayers);
      document.addEventListener('keyup', pressKey);
      return () => {
        document.removeEventListener('keydown', movePlayers);
        document.removeEventListener('keyup', pressKey);
      };
    } else {
      setCanvasReady(false);
    }
  }, [room.room.settings.isPaused]);

  // Handle Canvas Update
  useEffect(() => {
    if (canvasReady === true) {
      let canvasList = {};
      for (let [ctxKey, ctxVal] of Object.entries(ctx)) {
        canvasList = { ...canvasList, [ctxKey]: { ctx: ctxVal, size: { w: canvasWidth, h: canvasHeight } } };
      }
      setCache(room);
      updateCanvas(canvasList);
    }
  }, [room.players.p1.x, room.players.p2.x, canvasReady, canvasWidth, canvasHeight]);

  // DRAW WALL ON UPDATE LIFE
  useEffect(() => {
    if (canvasReady === true) {
      if (!room.room.settings.isPaused) {
        setDraw(10, 'objects', 'easeInQuad', room.objects.walls, false, false, false, true);
        setCache(room);
      }
    }
  }, [room.objects.updated]);

  // DRAW WALL ON LAST MOVE
  useEffect(() => {
    if (canvasReady === true) {
      if (!room.room.settings.isPaused) {
        setDraw(room.room.settings.objects.walls.moveSpeed, 'objects', 'easeInQuad', cache.objects.walls);
        setCache(room);
      }
    }
  }, [room.objects.lastMove, canvasReady]);

  // DRAW BULLETS
  useEffect(() => {
    if (canvasReady === true) {
      const result = Object.is(room.players.p1.firing.lastBullet, cache.players.p1.firing.lastBullet) ? 1 : 0;
      const playerFiring = 'p' + (result + 1);
      let bulletBlockTarget;
      let wallKey;
      for (let [objectKey, object] of Object.entries(room.objects.walls)) {
        if (object.life > 0 && object.x === room.players[playerFiring].x) {
          if (bulletBlockTarget) {
            if (playerFiring === 'p1') {
              bulletBlockTarget = room.room.settings.axesY - object.y < room.room.settings.axesY - bulletBlockTarget ? object.y : bulletBlockTarget;
              wallKey = room.room.settings.axesY - object.y < bulletBlockTarget ? objectKey : wallKey;
            } else {
              bulletBlockTarget = room.room.settings.axesY - object.y > room.room.settings.axesY - bulletBlockTarget ? object.y : bulletBlockTarget;
              wallKey = room.room.settings.axesY - object.y > bulletBlockTarget ? objectKey : wallKey;
            }
          } else {
            bulletBlockTarget = object.y;
            wallKey = objectKey;
          }
        }
      }
      setDraw(room.players[playerFiring].firing.firingSpeed, 'bullets_' + playerFiring, 'linear', cache.players, playerFiring, bulletBlockTarget, wallKey);
      setCache(room);
    }
  }, [room.players.p1.firing.lastBullet, room.players.p2.firing.lastBullet]);

  // DRAW ULTI
  useEffect(() => {
    if (canvasReady === true) {
      const result = Object.is(room.players.p1.ulti.lastActivation, cache.players.p1.ulti.lastActivation) ? 1 : 0;
      const playerFiring = 'p' + (result + 1);
      let bulletBlockTarget;
      let wallKey;
      for (let [objectKey, object] of Object.entries(room.objects.walls)) {
        if (object.life > 0 && object.x === room.players[playerFiring].x) {
          if (bulletBlockTarget) {
            bulletBlockTarget = room.room.settings.axesY - object.y < room.room.settings.axesY - bulletBlockTarget ? object.y : bulletBlockTarget;
            wallKey = room.room.settings.axesY - object.y < bulletBlockTarget ? objectKey : wallKey;
          } else {
            bulletBlockTarget = object.y;
            wallKey = objectKey;
          }
        }
      }
      setDraw(room.players[playerFiring].ulti.firingSpeed, 'ulti_' + playerFiring, 'linear', cache.players, playerFiring, bulletBlockTarget, wallKey);
      setCache(room);
    }
  }, [room.players.p1.ulti.lastActivation, room.players.p2.ulti.lastActivation]);

  // DRAW PLAYERS
  useEffect(() => {
    if (canvasReady === true) {
      drawPlayers('players_1', room.room.settings.players.moveSpeed, true);
    }
  }, [room.players.p1.x, canvasReady]);

  useEffect(() => {
    if (canvasReady === true) {
      drawPlayers('players_2', room.room.settings.players.moveSpeed, true);
    }
  }, [room.players.p2.x, canvasReady]);

  const drawPlayers = (playerCanvas, moveSpeed, moving) => {
    const playerAction = playerCanvas === 'players_1' ? 'p1' : 'p2';
    const lastX = moving ? room.players[playerAction].lastX : room.players[playerAction].x;
    setDraw(moveSpeed, playerCanvas, 'easeOutQuint', lastX);
  };

  // LAUNCH DEFENSE
  useEffect(() => {
    if (canvasReady === true) {
      if (room.players['p1'].lastMoveX + room.room.settings.players.moveSpeed < Date.now()) {
        drawPlayers('players_1', 1, false);
      }
    }
  }, [canvasReady, room.players.p1.defense.lastActivation]);

  useEffect(() => {
    if (canvasReady === true) {
      if (room.players['p2'].lastMoveX + room.room.settings.players.moveSpeed < Date.now()) {
        drawPlayers('players_2', 1, false);
      }
    }
  }, [canvasReady, room.players.p2.defense.lastActivation]);

  //STOP DEFENSE
  useEffect(() => {
    if (!room.players.p1.defense.isActive && canvasReady === true) {
      if (room.players['p1'].lastMoveX + room.room.settings.players.moveSpeed < Date.now()) {
        drawPlayers('players_1', 1, false);
      }
    }
  }, [room.players.p1.defense.isActive]);

  useEffect(() => {
    if (!room.players.p2.defense.isActive && canvasReady === true) {
      if (room.players['p2'].lastMoveX + room.room.settings.players.moveSpeed < Date.now()) {
        drawPlayers('players_2', 1, false);
      }
    }
  }, [room.players.p2.defense.isActive]);

  return (
    <div ref={canvasComp} onKeyDown={(e) => movePlayers(e)} className='canvas' tabIndex='0'>
      {!room.room.settings.isPaused && <canvas id='players_1' className='canvas_content' height={canvasHeight} width={canvasWidth} />}
      {!room.room.settings.isPaused && <canvas id='players_2' className='canvas_content' height={canvasHeight} width={canvasWidth} />}
      {!room.room.settings.isPaused && <canvas id='bullets_p1' className='canvas_content' height={canvasHeight} width={canvasWidth} />}
      {!room.room.settings.isPaused && <canvas id='bullets_p2' className='canvas_content' height={canvasHeight} width={canvasWidth} />}
      {!room.room.settings.isPaused && <canvas id='ulti_p1' className='canvas_content' height={canvasHeight} width={canvasWidth} />}
      {!room.room.settings.isPaused && <canvas id='ulti_p2' className='canvas_content' height={canvasHeight} width={canvasWidth} />}
      {!room.room.settings.isPaused && <canvas id='objects' className='canvas_content' height={canvasHeight} width={canvasWidth} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCanvas: (payload) =>
      dispatch({
        type: 'UPDATE_CANVAS',
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
