import { connect } from "react-redux";
import { getRooms, unenqueueRoom, readRoom, writeRoom } from "../../../helpers/firestore/crud";
import { updateUser } from "../../../helpers/mongo/crud";
import { getUser } from "../../../helpers/mongo/crud";
import { initRoom, joinRoom } from "../Functions/Rooms";
import React, { useEffect } from "react";
import ButtonSkew from "../../ButtonSkew/ButtonSkew";
import CharacterChooser from "../../CharacterChooser/CharacterChooser";
import errors from "../../../helpers/settings/errors.json";

function Matchmaking({ local }) {
  const characters = ["chat", "chouette", "grenouille", "hibou", "lapin"];

  const MatchmakingManager = async () => {
    if (local.userID.length < 4) {
      return;
    }
    let availableRooms;
    await getRooms().then((r) => {
      availableRooms = Object.values(r);
    });
    if (availableRooms.length === 0) {
      initRoom(characters[local.userCharacter]);
    } else {
      joinRoom(availableRooms[0], characters[local.userCharacter]);
    }
  };

  useEffect(() => {
    if(local.roomID !== 0) {
      updateUser(local.userID, { lastRoom: local.roomID})
    }
  }, [local.roomID])

  useEffect(() => {
    if(local.userID !== undefined) {
      getUser(local.userID).then((e) => {
        if(e.lastRoom !== 0) {
          readRoom(e.lastRoom).then((r) => {
            if(r.players.p2.status.connected && r.players.p1.status.userID === local.userID) {
              console.log(e.lastRoom)
              writeRoom(e.lastRoom, {
                room: {
                  leaved: true
                }
              }, true)
            }
          })
          unenqueueRoom(e.lastRoom)
        }
        setTimeout(() => {updateUser(local.userID, { lastRoom: 0})}, 1000)
      })
    }
  }, [local.userID])

  return (
    <div className="roomManager">
      <div className="roomManager__wrapper">
        <CharacterChooser />
        <div className="form">
          <div className="form__wrapper">
            <ButtonSkew onClick={(e) => MatchmakingManager()} name="Trouver un adversaire" btnClass="small" />
            {local.error === "games_not_found" && <p>{errors["games_not_found"]}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

export default connect(mapStateToProps)(Matchmaking);
