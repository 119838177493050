import store from '../../../store/global'
import { updateRoomId, updatePlayerID, newError, updateUserID } from '../../../store/actions/index'
import { createRoom, writeRoom, readRoom, enqueueRoom, unenqueueRoom, getRooms } from '../../../helpers/firestore/crud';
import { getUser } from "../../../helpers/mongo/crud";

let local;

store.subscribe(() => {
  local = store.getState().local;
});

export const joinRoom = async (roomID, character) => {
  if (roomID === undefined) {
    if(local.matchmaking === true) {
      store.dispatch(newError('games_not_found'))
    }
    return;
  }
  if(local.userID.length < 4 || typeof local.userID !== 'string') {
    store.dispatch(newError('invalid_user_id'))
    return
  }
  await readRoom(roomID).then(async (r) => {
    if(!r.players) {
      initRoom(character)
      unenqueueRoom(roomID)
      return
    }
    if(r.players.p1.status.userID === local.userID) {
      if(local.matchmaking === true) {
        initRoom(character)
      } else {
        store.dispatch(newError('still_connected_as_p1'))
      }
      return
    }
    if(r.players.p1.status.character === character) {
      if(local.matchmaking === true) {
        initRoom(character)
      } else {
        store.dispatch(newError('same_character'))
      }
      return
    }
    if(local.matchmaking === true) {
      const hasLeaved = await getUser(r.players.p1.status.userID).then((u) => {
        console.log(u.lastRoom)
        console.log(roomID)
        if(u.lastRoom !== roomID) {
          return true
        } else {
          return false
        }
      })
      unenqueueRoom(roomID)
      if(hasLeaved === true) {
        let availableRooms;
        await getRooms().then((r) => {
          availableRooms = Object.values(r);
        });
        setTimeout(() => joinRoom(availableRooms[0], character), 1000)
        return
      }
    }
    const isFull = r.room.status.full;
    if (local.playerID === undefined) {
      // Add Player 2
      if (!isFull & (local.userID !== r.players.p1.status.userID)) {
        await writeRoom(
          roomID,
          {
            room: { status: { full: true, stage: 'waiting' } },
            players: { p2: { status: { connected: true, userID: local.userID, character: character } } },
          },
          true
        );
        await store.dispatch(updatePlayerID(1));
        await store.dispatch(updateUserID(local.userID));
        await store.dispatch(updateRoomId(roomID));
      } else {
        let id = 0;
        for (let playerKey in r.players) {
          if (r.players[playerKey].status.userID === local.userID) {
            await store.dispatch(updatePlayerID(id));
            await store.dispatch(updateRoomId(roomID));
            return;
          }
          id += 1;
        }
        store.dispatch(newError('Trop de joueurs dans la partie. Lancez-en une autre'));
        return;
      }
    }
  })
};

// Create a new room & set first player to connected
export const initRoom = (character) => {
  if (local.userID === undefined) {
    store.dispatch(newError('login'))
    return;
  }
  if(local.userID.length < 4 || typeof local.userID !== 'string') {
    store.dispatch(newError('invalid_user_id'))
    return
  }
  createRoom().then(async (id) => {
    await writeRoom(
      id,
      {
        room: { duration: { startingTime: Date.now() }, status: { stage: 'waiting' } },
        players: { p1: { status: { connected: true, userID: local.userID, character: character } } },
      },
      true
    );
    await store.dispatch(updatePlayerID(0));
    await store.dispatch(updateUserID(local.userID))
    await store.dispatch(updateRoomId(id));
    if(local.matchmaking === true) {
      enqueueRoom(id)
    }
  });
};