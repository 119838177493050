import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './Timer.scss';
import { writeRoom } from '../../../helpers/firestore/crud';

function Timer({ local }) {
  const [timeSecond, setTimeSecond] = useState(3);

  useEffect(() => {
    if (timeSecond > -1) {
      setTimeout(() => {
        setTimeSecond(timeSecond - 1);
      }, 1000);
    } else if (timeSecond === -1) {
      writeRoom(local.roomID, { room: { status: { stage: 'playing', gameStart: Date.now() } } }, true);
      new Audio(process.env.PUBLIC_URL + '/sounds/startGame.mp3').play();
    }
  }, [timeSecond]);

  return (
    <div className='loading_timer'>
      <div className='loading_timer__wrapper'>
        <p className='loading_timer__title'>Débute dans :</p>
        <div className='loading_timer__counter'>
          <p className='loading_timer__counter__time'>{timeSecond}</p>
        </div>
        <p className='loading_timer__bg'>{timeSecond}</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
  };
};

export default connect(mapStateToProps)(Timer);
