import "./ButtonSkew.scss";

export default function ButtonSkew(props) {
  return (
    <button onClick={props.onClick} className={props.btnClass + " btnSkew"}>
      <div className={props.type + "__text fauna"}>
        {props.name} {props.icon && <img src={"/icons/" + props.icon + ".svg"} alt="Button icon" />}
      </div>
    </button>
  );
}
