import { connect } from 'react-redux'
import React, { useEffect } from 'react';
import './Error.scss'
import errors from '../../../helpers/settings/errors.json'

function Error({ local, newError }) {

  useEffect(() => {
    if(local.error !== '') {
      setTimeout(() => {
        newError('')
      }, 10000)
    }
  }, [local.error])

  return(
    <div className={'error ' + (local.error !== '' ? 'visible' : '')}>
      <img className='error__warning' src="/icons/error.svg" alt="" />
      <p className='error__message'>{errors[local.error]}</p>
      <div onClick={(e) => newError('')} className='error__close'>
        <p>Fermer</p>
        <img src="/icons/close.svg" alt="" />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    local: state.local
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newError: (payload) =>
      dispatch({
        type: "NEW_ERROR",
        payload,
      }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
