import "./Help.scss";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { connect } from "react-redux";

function Help({ openHelp }) {
  return (
    <div>
      <div className="containerRules">
        <div className="globalRules">
          <div className="rulesTitle">
            <h1 className="fauna">Règles et déroulement de la partie</h1>
            <img id="closeRules" src="/icons/close.svg" alt="icone croix" onClick={(e) => openHelp(false)} />
          </div>

          <div className="leftrightRules">
            <div className="leftRules">
              <div className="howPlay">
                <h2>Comment jouer ?</h2>
                <div className="textPlay">
                  <p>
                    Incarne un avatar et tente d’<span className="weightSemibold">éliminer ton adversaire</span> à l’aide de projectiles. Chaque impact inflige des dégâts. À toi de
                    jouer pour éliminer ton ennemi le plus rapidement possible.
                  </p>
                  <p>
                    Pendant la partie tu peux réaliser <span className="weightSemibold">une attaque spéciale</span> permettant d’infliger plus de dégat à ton adversaire. A
                    contrario, tu as aussi la possibilité de te défendre afin de parer les coups de son adversaire. Ces attaques se rechargent en 10 secondes après chaque usage.
                  </p>
                  <p>
                    <span className="weightSemibold">La partie se décompose en trois manches de 30 secondes</span>. Après chaque manche, pour pimenter le jeu, tu dois choisir un
                    bonus d’attaque, de défense ou random. À toi de faire le choix le plus stratégique afin de vaincre ton adversaire.
                  </p>
                  <p>
                    Une partie classique dure <span className="weightSemibold">1 minute 30</span>, à la fin de cette partie si aucun d’entre vous n’a rendu les armes, le dernier
                    round continue jusqu'à ce que l'un de vous soit mort.
                  </p>
                </div>

                <div className="controls">
                  <div class="inputControl">
                    <div className="chevron">
                      <FaChevronUp />
                    </div>
                    <div className="textChevron">Lancer une attaque spéciale</div>
                  </div>
                  <div class="inputControl">
                    <div className="chevron">
                      <FaChevronDown />
                    </div>
                    <div className="textChevron">Se défendre</div>
                  </div>
                  <div class="inputControl">
                    <div className="chevron">
                      <FaChevronLeft />
                    </div>
                    <div className="textChevron">Se déplacer vers la droite</div>
                  </div>
                  <div class="inputControl">
                    <div className="chevron">
                      <FaChevronRight />
                    </div>
                    <div className="textChevron">Se déplacer vers la droite</div>
                  </div>
                  <div class="inputControl">
                    <div className="textChevron">
                      <span>espace</span> Effectuer un tir
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rightRules">
              <div className="playModes">
                <h2>Modes de jeu</h2>
                <p>
                  Chaque partie est unique ! En début de partie, <span className="weightSemibold">le mode de jeu est tiré au sort</span> : il permet de{" "}
                  <span className="weightSemibold">personnaliser la partie</span>.
                </p>
                <p>
                  {" "}
                  <span className="weightSemibold">6 modes de jeu peuvent s'appliquer à toi et ton adversaire :</span>{" "}
                </p>

                <div>
                  <ul>
                    <li>Un mur mouvant</li>
                    <li>Un mur blindé mouvant</li>
                    <li>Plus de bonus de vie</li>
                    <li>Multiplication des points X2</li>
                    <li>Cadence des tirs X2</li>
                    <li>Dégâts des tirs X2</li>
                  </ul>
                </div>
                <p id="lastp"></p>
              </div>

              <div className="cardsAD">
                <h2>Les cartes Attaque et Défense</h2>
                <p>
                  La fin d’une manche te permet de choisir <span className="weightSemibold">une carte d’attaque ou de défense</span> .
                </p>
                <p>
                  <span className="weightSemibold">Tu peux recevoir 3 types de bonus attaque :</span> <br /> - Rechargement plus rapide de l’attaque spéciale <br />
                  - Augmentation des dégâts de l’attaque spéciale <br />- Augmentation des dégâts de base
                </p>
                <p>
                  <span className="weightSemibold">Et 3 types de bonus défense :</span> <br />
                  - Un bouclier plus puissant <br />
                  - Rechargement plus rapide du bouclier <br />- Bonus de vie
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openHelp: (payload) =>
      dispatch({
        type: "SET_HELP",
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
