import { useState } from "react";
import "./InGame.scss";
import { useEffect } from "react";
// import { FaChevronLeft, FaChevronUp, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import mode from "../../helpers/settings/modes.json";
import Canvas from "./Canvas/Canvas";
import GameHeader from "./GameHeader/GameHeader";
import Ending from "../Ending/Ending";
import Pause from "./Pause/Pause";
import { writeRoom } from "../../helpers/firestore/crud";
import modes from "../../helpers/settings/modes.json";
import Capacities from "./Capacities/Capacities";

function InGame({ room, local }) {
  let isPaused = room.room.settings.isPaused;
  const [settings, setSettings] = useState({ capacity: "undefined" });
  let objectsList = {};
  const ennemy = local.playerID + 1 === 1 ? "p2" : "p1";
  const [isFinished, SetIsFinished] = useState();
  const [touched, setTouched] = useState("");

  useEffect(() => {
    setSettings({
      ...settings,
      mode: mode.room[room.room.settings.gameMode].name,
      modeDesc: mode.room[room.room.settings.gameMode].desc,
    });
  }, [local.roomID]);

  // DRAW WALLS
  useEffect(() => {
    if (local.playerID === 0) {
      objectsList = {};
      if (isPaused) {
        writeRoom(
          local.roomID,
          {
            objects: {
              updated: Date.now(),
              walls: {},
            },
          },
          true
        );
        for (let i = 0; i < room.room.settings.objects.walls.totalNumber + 1; i++) {
          createObjects(i);
        }
      } else if (room.room.settings.round === 1) {
        for (let i = 0; i < room.room.settings.objects.walls.totalNumber + 1; i++) {
          createObjects(i);
        }
      }
    }
  }, [room.room.settings.isPaused]);

  useEffect(() => {
    if (room.players.p1.status.alive === false || room.players.p2.status.alive === false) {
      SetIsFinished(true);
      new Audio(process.env.PUBLIC_URL + "/sounds/endGame.mp3").play();
      writeRoom(
        local.roomID,
        {
          room: {
            duration: {
              endGame: Date.now(),
            },
          },
        },
        true
      );
    } else {
    }
  }, [room.players.p1.status.alive, room.players.p2.status.alive]);

  const createObjects = (i) => {
    const objectX = Math.floor(Math.random() * (room.room.settings.axesX - 1 - 1) + 1);
    const objectY = Math.floor(Math.random() * (room.room.settings.axesY - 2 - 3) + 3);
    const life = room.room.settings.objects.walls.life;
    const move = room.room.settings.gameMode === "wallMove" ? true : false;
    const direction = Math.round(Math.random()) === 1 ? "left" : "right";
    if (i < room.room.settings.objects.walls.totalNumber) {
      let rand = Math.random();
      if (room.room.settings.gameMode === "armoredWalls") {
        if (rand < 0.5) rand = 0;
        else rand = 1;
      } else {
        rand = 1;
      }

      if (i === 0) {
        objectsList = {
          ...objectsList,
          [objectX + "_" + objectY]: {
            x: objectX,
            y: objectY,
            type: "wall",
            life: rand === 0 ? life : life - modes.room.armoredWalls.value,
            move: move,
            mode: room.room.settings.gameMode,
            direction: direction,
          },
        };
      } else {
        let positionExist;
        // eslint-disable-next-line no-unused-vars
        for (let [objKey, objValue] of Object.entries(objectsList)) {
          if (!positionExist) {
            positionExist = objValue.x === objectX || objValue.y === objectY ? true : false;
          }
        }
        if (!positionExist) {
          objectsList = {
            ...objectsList,
            [objectX + "_" + objectY]: {
              x: objectX,
              y: objectY,
              type: "wall",
              life: rand === 0 ? life : life - modes.room.armoredWalls.value,
              move: move,
              mode: room.room.settings.gameMode,
              direction: direction,
            },
          };

        } else {
          createObjects(i);
        }
      }
    } else {
      writeRoom(
        local.roomID,
        {
          objects: {
            updated: Date.now(),
            walls: objectsList,
          },
        },
        true
      );
    }
  };

  useEffect(() => {
    if (room.objects.updated !== "empty") {
      setTouched("inflicted-" + room.players["p" + (local.playerID + 1)].status.character);
      setTimeout(() => {
        setTouched("");
      }, 2000);
    }
  }, [room.players["p" + (local.playerID + 1)].firing.lastTouched.ulti]);

  useEffect(() => {
    if (room.objects.updated !== "empty") {
      setTouched("touched");
      setTimeout(() => {
        setTouched("");
      }, 2000);
    }
  }, [room.players[ennemy].firing.lastTouched.ulti]);

  return (
    <div className={"mainContent touchAnim " + touched}>
      {/* EXPLICATIONS & CARTES */}
      <div className="partLeft">
        <div className="contentPart">
          <div className="logo">
            <img src={process.env.PUBLIC_URL + "/icons/logo.svg"} alt="Halto Logo"/>
          </div>
          <div className="textModeGame">Mode de jeu :</div>
          <h2 className="modeGame">{settings.mode}</h2>
          <div className="modeConsist">{settings.modeDesc}</div>
          <p class="roomID">Numéro de room : {local.roomID}</p>
        </div>
        <div className="cards">
          <div className="card">
            {room.players["p" + (local.playerID + 1)].capacities.card1 != null && (
              <img src={process.env.PUBLIC_URL + "/img/bonus/" + room.players["p" + (local.playerID + 1)].capacities.card1 + ".webp"} alt="Bonus du joueur"/>
            )}
          </div>
          <div className="card">
            {room.players["p" + (local.playerID + 1)].capacities.card2 != null && (
              <img src={process.env.PUBLIC_URL + "/img/bonus/" + room.players["p" + (local.playerID + 1)].capacities.card2 + ".webp"} alt="Bonus du joueur"/>
            )}
          </div>
        </div>
      </div>
      {/* CANVA & GAMEPLAY */}
      <div className="gamePart">
        <GameHeader />
        {room.objects.updated !== "empty" && isPaused && <Pause />}
        {room.objects.updated !== "empty" && isFinished && <Ending />}
        {room.objects.updated !== "empty" && <Canvas />}
      </div>
      {/* CONTROLES & ULTI */}
      <div className="partRight">
        <Capacities />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    local: state.local,
  };
};

export default connect(mapStateToProps)(InGame);
