import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./RoomWaiting.scss";
import ButtonSkew from "../../ButtonSkew/ButtonSkew";
import { writeRoom } from "../../../helpers/firestore/crud";

function RoomWaiting({ room, local, updateStage }) {
  const copiedText = React.useRef();
  const [playersReady, setplayersReady] = useState(false);

  useEffect(() => {
    for (let player in room.players) {
      if (room.players[player].status.ready === false) {
        return;
      }
    }
    setplayersReady(true);
  }, [room.players.p1.status.ready, room.players.p2.status.ready]);

  const copyLink = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_APPURL + "?query=join&room=" + local.roomID);
    copiedText.current.classList.add("visible");
    let audio = new Audio(process.env.PUBLIC_URL + "/sounds/click.mp3").play();
    audio.volume = 0.5;
    audio.play();
    setTimeout(() => {
      copiedText.current.classList.remove("visible");
    }, 3000);
  };

  return (
    <div className="roomWaiting">
      <div className="roomWaiting__wrapper">
        <div className="roomWaiting__player">
          <div className={"roomWaiting__player__character " + room.players.p1.status.character}>
            <img src={"img/skins/" + room.players.p1.status.character + ".png"} alt={"Personnage : " + room.players.p1.status.character} />
          </div>
          <p className="joueur">{room.players.p1.status.userID ? room.players.p1.status.userID : "Joueur 2"}</p>
          {room.players.p1.status.ready === false ? (
            <div>
              <p className="roomWaiting__player__notready">En attente</p>
              {"p1" === "p" + (local.playerID + 1) && (
                <ButtonSkew
                  onClick={(e) => writeRoom(local.roomID, { players: { p1: { status: { ready: true } } } }, true)}
                  type="btnPlayer1"
                  name="PRÊT"
                  btnClass="buttonJoin small"
                />
              )}
            </div>
          ) : (
            <div>
              <p className="roomWaiting__player__ready">
                <img src="/icons/check.svg" alt="Icone Prêt"/>
                Prêt !
              </p>
            </div>
          )}
        </div>

        <div className="roomWaiting__versus">
          <p id="vs" className="fauna">
            VS
          </p>
          <p className="roomWaiting__versus__roomID">{local.roomID}</p>
          {playersReady && local.playerID === 0 && (
            <ButtonSkew onClick={(e) => writeRoom(local.roomID, { room: { status: { stage: "launching" } } }, true)} name="GO" btnClass="buttonJoin extraLarge" />
          )}
          {local.matchmaking === false && local.playerID === 0 && (
            <ButtonSkew onClick={(e) => copyLink()} className="roomWaiting__versus__link" name="Inviter" icon="link" btnClass="small" />
          )}
          <div ref={copiedText} className="roomWaiting__versus__copiedText">
            Lien copié !
          </div>
        </div>

        <div className="roomWaiting__player">
          <div className={"roomWaiting__player__character " + room.players.p2.status.character}>
            <img src={"img/skins/" + room.players.p2.status.character + ".png"} alt="" />
          </div>
          <p className="joueur">{room.players.p2.status.userID ? room.players.p2.status.userID : "Joueur 2"}</p>
          {room.players.p2.status.ready === false ? (
            <div>
              <p className="roomWaiting__player__notready">En attente</p>
              {"p2" === "p" + (local.playerID + 1) && (
                <ButtonSkew
                  onClick={(e) => writeRoom(local.roomID, { players: { p2: { status: { ready: true } } } }, true)}
                  type="btnPlayer1"
                  name="PRÊT"
                  btnClass="buttonJoin small"
                />
              )}
            </div>
          ) : (
            <div>
              <p className="roomWaiting__player__ready">
                <img src="/icons/check.svg" alt="Icone Prêt"/>
                Prêt !
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    local: state.local,
    room: state.room,
  };
};

export default connect(mapStateToProps)(RoomWaiting);
