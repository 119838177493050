import './Stats.scss';
import { connect } from 'react-redux';

function Stats({ room, local }) {
  let gameTime = (room.room.duration.endGame - room.room.duration.startingTime) / 1000;

  const totalShoots = room.players['p' + (local.playerID + 1)].firing.totalShoots;
  const totalTouched = room.players['p' + (local.playerID + 1)].firing.totalTouched;
  const ratio = (totalTouched / totalShoots) * 100;
  const gauge = ratio > 70 ? 'positive' : 'negative';
  return (
    <div className='stats part-dashboard'>
      <h3 class='dashboard-subtitle'>Mes statistiques de la partie</h3>
      <div className='stats-subcontainer'>
        <div className='win-stats'>
          <div className='win-percent'>
            <div className={'jauge ' + gauge}>
              <span>{Math.round(ratio)} %</span> <p>de tirs réussis</p>
            </div>
          </div>
          <div className='loose-win-stats'>
            <div className='win-loose'>
              <span>{totalTouched}</span> tirs réussis
            </div>
            <div className='win-loose'>
              <span>{totalShoots - totalTouched}</span> tirs ratés
            </div>
          </div>
        </div>
        <div className='statistiques'>
          <div className='subelement'>Partie terminée en {Math.round(gameTime)} secondes</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    local: state.local,
  };
};

export default connect(mapStateToProps)(Stats);
